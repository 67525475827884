import React, { useState } from 'react';
import Loader from '../partials/Loader';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Alert, Button } from 'react-bootstrap';
import * as Yup from "yup";
import constants from '../../constant';
import axios from "axios";
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';

function ForgetPassword(){
   //state variables
   const [state, setState] = useState({
       variant : '',
       message : ''
   })
   const [showLoader, setLoader] = useState(false);
   const [error, setError] = useState(false);
   //get params from the url
   const params = useParams();

   const resetPassword = async(values,resetForm) => {
       try {
          setLoader(true);
          const url = `${constants.BASE_APP_URL}/users/reset_password`;
          let data = {
              password : values.password,
              token : params.token,
              timezone : moment.tz.guess()
          }
          let response = await axios.put(url,data);
          resetForm();
          setLoader(false);
          setError(true);
          setState({
              variant : 'success',
              message : response.data.message
          })
          setTimeout(()=>{
            setError(false);
          },3000);
       } catch (error) {
           setLoader(false);
           setError(true);
           setState({
               variant : 'danger',
               message : error.response.data.message
           });
           setTimeout(()=>{
               setError(false);
           },3000);
       }
   }

   //variable for form submission
   const validate = Yup.object({
        password: Yup.string().required("Password is required.").matches(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,'Password should be combination of one uppercase , one lower case, one special char, one digit and min 8 , max 20 char long'),
        confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match.'),
    });
    const initialValues  = {
        password: "",
        confirm_password: "",
    };
   return(
        <div className="loginWrap">
        { showLoader && <Loader /> }
        <div className="loginOuter">
            <h1 className="text-center mb-4 themeColor">Tradesk</h1>
            <div className="login">
                <div className="pageTitle__outer text-center">
                    <h2 className="pageTitle w-100">Reset Password</h2>
                </div>
                {error && <Alert variant={state.variant}>{state.message}</Alert> }
                <Formik
                    initialValues={initialValues}
                    validationSchema={validate}
                    onSubmit={(values, actions) => {
                        resetPassword(values, actions.resetForm)
                    }}
                    >
                    <Form> 
                        <div className="form-group mb-4">
                            <Field type="password" name="password" className="form-control" placeholder="New Password" />
                            <ErrorMessage name="password" component="div" className="text-danger" />
                        </div>
                        <div className="form-group mb-4">
                            <Field className="form-control" type="password" name="confirm_password" placeholder="Confirm Password" />
                            <ErrorMessage name="confirm_password" component="div" className="text-danger" />
                        </div>
                        <div className="form-group text-center">
                            <Button type='submit' variant="primary">Submit</Button>{' '}
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    </div>
   );
}

export default ForgetPassword;