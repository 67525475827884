import React, { useEffect, useState } from "react";
import { Row, Col, Toast, ToastContainer, Button, Modal } from 'react-bootstrap';
import moment from "moment";
import momentTz from 'moment-timezone';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import axios from 'axios';
import constant from "../../constant";
import Loader from "../partials/Loader";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

function ReminderList(){
    //state variables
    const [events,setEvents] = useState([]);
    const [showLoader, setLoader] = useState(false);
    const [showToast, setToast] = useState(false);
    const [message, setMessage] = useState('');
    const [eventDate, setEventDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateType, setDateType] = useState('week');
    const [state, setState] = useState({
        todayReminder : [],
        tomorrowReminder : [], 
        total_reminder_count : [],
        calendarDate : moment().format('YYYY-MM-DD'),
        eventData : {
            date : '',
            description : '',
            title : ''
        }
    });
    const [showModal, setModal] = useState(false);

    let navigate = useNavigate();

    const getReminder = async()=>{
        try {
           setLoader(true);
           let timezone = moment.tz.guess();
           let url = `${constant.BASE_API_URL}/reminder/getReminders?timezone=${timezone}&date=${eventDate}&dateType=${dateType}`;
           let response = await axios.get(url, {headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}`}});
           let result = response.data.data;
           if(dateType === 'week'){
               setReminderEvents(result.remainderData);
           }else{
                let iscurrentDate = moment().isSame(eventDate, "day");
                if(iscurrentDate){
                    setTodayReminder(result.remainderData);
                }else{
                    setTomorrowReminder(result.remainderData);
                    setLoader(false);
                    setMessage('Reminder details.');
                    setToast(true);
                }
           } 
           //set total reminder count 
           setState({
             ...state,
             total_reminder_count : result.reminderCount
           });         
        } catch (error) {
           setToast(true);
           setLoader(false);
           setMessage('Something went wrong.');
        }
    }

    useEffect(()=>{
       getReminder();

       //get data according to today
       let todayTimer = setTimeout(()=>{          
          setDateType('day');
       },1000);

       //get data according to tomorrow
       let tomorrowTimer = setTimeout(()=>{
          setEventDate(moment().add(1,'days').format('YYYY-MM-DD'));
       },2000);
      
       return () => {
          clearTimeout(todayTimer);
          clearTimeout(tomorrowTimer);
       }
       // eslint-disable-next-line
    },[eventDate,dateType]);

    const getEventDate = async($e) =>{
        try {
            setLoader(true);
            let date = moment($e).format('YYYY-MM-DD');
            let timezone = moment.tz.guess();
            let url = `${constant.BASE_API_URL}/reminder/getReminders?timezone=${timezone}&date=${date}&dateType=week`;
            let response = await axios.get(url, {headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}`}});
            let result = response.data.data;  
            setReminderEvents(result.remainderData);
            //set total reminder count 
            setState((prevState)=>({
                ...prevState,
                total_reminder_count : result.reminderCount,
                calendarDate : $e
            }));
            setLoader(false);
            setMessage('Reminder details.');
            setToast(true);
        } catch (error) {
           setToast(true);
           setLoader(false);
           setMessage('Something went wrong.');
        }
    }

    const setReminderEvents = (result)=>{
        let event = [];
        result.forEach((element,index) => {
            let val = {
              id: index,
              title: element.description,
              start: moment(element.dateTime).tz(element.timezone).toDate(),
              end: moment(element.dateTime).tz(element.timezone).toDate(),
              desc: `Created by ${element.created_by.name}`
            } 
            event.push(val);
        });
        setEvents(event);
    }

    const setTodayReminder = (result)=>{
        setState({
            ...state,
            todayReminder : result
        })
    }    

    const setTomorrowReminder = (result)=>{
        setState({
            ...state,
            tomorrowReminder : result
        });
    }

    const setEventData = ($e) => {
        setModal(true);
        setState({
            ...state,
            eventData : {
                title : $e.title,
                date : $e.start,
                description : $e.desc
            }
        })
    }

    const checkDate = (date) =>{
        let calDate = moment(date,'YYYY-MM-DD').format('YYYY-MM-DD');
        let match_date = state.total_reminder_count.find((res)=> res.date === calDate);
        if(match_date){
            return true
        }else{
            return false;
        }
    }

    return(
        <div>
        {showLoader && <Loader />}
        <ToastContainer className="p-3" position="top-end">
            <Toast show={showToast} onClose={()=>setToast(false)} autohide>
            <Toast.Header>
                <strong className="me-auto">Tradesk</strong>
                <small>just now</small>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
            </Toast>
        </ToastContainer>
        <div className="pageTitle__outer">
            <h2 className="pageTitle">Reminder Details</h2>
            <div className="filterBtn">
                <Button variant="primary" onClick={()=>navigate('/calendar/addReminder')}>+ Add Reminder</Button>
            </div>
        </div>
        <div className="whiteBox">
            <Row>
                <Col md={4}>
                    <div className="eventCalender">
                      <DatePicker
                       // selected={eventDate} 
                        dateFormat="MM/dd/yyyy"
                        className="form-control"
                        calendarClassName="rasta-stripes"
                        onChange={(date) => getEventDate(date)}
                        dayClassName={(date) => checkDate(date) ? "eventOrange" : ""}
                        inline
                      />
                    </div>
                    <div className="eventListWrap">
                        <h4 className="eventTitle">Today {moment().format('MM/DD/YYYY')}</h4>
                        <div className="eventList">
                            <ul>
                                { state.todayReminder.map((item,index)=>(
                                    <li key={index}>
                                        <h6 className="eventTime">{momentTz(item.dateTime).tz(item.timezone).format('hh:mm a')}</h6>
                                        <p className="eventName">{item.description}</p>
                                        <p className="eventName"><strong>Created by {item.created_by.name}</strong></p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="eventListWrap">
                        <h4 className="eventTitle">TOMORROW {moment().add(1,'days').format('MM/DD/YYYY')}</h4>
                        <div className="eventList">
                            <ul>
                                { state.tomorrowReminder.map((item,index)=>(
                                    <li key={index}>
                                        <h6 className="eventTime">{momentTz(item.dateTime).tz(item.timezone).format('hh:mm a')}</h6>
                                        <p className="eventName">{item.description}</p>
                                        <p className="eventName"><strong>Created by {item.created_by.name}</strong></p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col md={8}>
                    <div style={{ height: '450pt'}}>
                        <Calendar
                            selectable
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            date={moment(state.calendarDate).toDate()}
                            localizer={localizer}
                            onNavigate={getEventDate}
                            views={{ week: true }}
                            defaultView={Views.WEEK}
                            onSelectEvent={setEventData}
                        />
                    </div>
                </Col>
            </Row>
        </div>
        <Modal show={showModal} onHide={()=>setModal(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                 <Modal.Title>Reminder Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="eventDetailPopup">
                    <p><b>Date:</b> {moment(state.eventData.date).format('MM/DD/YYYY h:mm a')}</p>
                    <p><b>Title:</b> { state.eventData.title }</p>
                    <p><b>Description:</b> { state.eventData.description }</p>
                </div>
            </Modal.Body>
        </Modal>
    </div>
    )
}

export default ReminderList;