import React, { Component } from "react";
import { Row, Col, Table, Form, Tab, Nav, Toast, ToastContainer } from "react-bootstrap";
import constant from "../../constant";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import Loader from '../partials/Loader';

class IncomeExpenses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            revenueData: [],
            showTost: false,
            message: "",
            toggleToast: () => {
                this.setState((prevState, prevProps) => ({
                    showTost: !prevState.showTost,
                }));
            },
            type: "invoice",
            status: "completed",
            page: 1,
            totalPages: 1,
            limit: 10,
            expensesData: [],
            showLoader : false,
            toggleLoader : () => {
                this.setState((prevState,prevProps)=>({
                    showLoader : !prevState.showLoader
                }))
            }
        };
    }
    componentDidMount = () => {
        this.getRevenueDetails();
    };
    getRevenueDetails = () => {
        this.state.toggleLoader();
        let apiUrl = `${constant.BASE_API_URL}/jobs/revenue_details?type=${this.state.type}&status=${this.state.status}`;
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        fetch(apiUrl, requestOptions)
            .then((res) => res.json())
            .then(
                (result) => {
                    this.state.toggleLoader();
                    console.log(result);
                    this.setState((prevState, prevProps) => ({
                        revenueData: result.data,
                        showTost: true,
                        message: "Revenue details.",
                    }));
                },
                (error) => {
                    this.state.toggleLoader();
                    this.setState({
                        showTost: true,
                        message: "Something went wrong.",
                    });
                }
            );
    };
    getExpensesList = () => {
        this.state.toggleLoader();
        let apiUrl = `${constant.BASE_API_URL}/jobs/expenses_list?page=${this.state.page}&limit=${this.state.limit}`;
        const requestOptions = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        fetch(apiUrl, requestOptions)
            .then((res) => res.json())
            .then(
                (result) => {
                    this.state.toggleLoader();
                    console.log(result);
                    this.setState((prevState, prevProps) => ({
                        expensesData: result.data.expenses_list,
                        page: result.data.page,
                        totalPages: result.data.totalPages,
                        limit: result.data.limit,
                        showTost: true,
                        message: "Expenses details.",
                    }));
                },
                (error) => {
                    this.state.toggleLoader();
                    this.setState({
                        showTost: true,
                        message: "Something went wrong.",
                    });
                }
            );
    };
    handleTab = (type) => {
        let status = type === "Revenue" ? "completed" : "all";
        this.setState(
            {
                status,
            },
            () => {
                this.getRevenueDetails();
            }
        );
    };
    paginationHandlig = (data) => {
        this.setState(
            {
                page: parseInt(data.selected) + 1,
            },
            () => {
                this.getExpensesList();
            }
        );
    };
    selectChange = (event) => {
        this.setState(
            (prevState, prevProps) => ({
                limit: event.target.value,
            }),
            () => {
                this.getExpensesList();
            }
        );
    };
    render() {
        return (
            <Row>
                <Col md={12}>
                   { this.state.showLoader && <Loader /> }
                    <ToastContainer className="p-3" position="top-end">
                        <Toast
                            show={this.state.showTost}
                            onClose={this.state.toggleToast}
                            autohide
                        >
                            <Toast.Header>
                                <strong className="me-auto">Tradesk</strong>
                                <small>just now</small>
                            </Toast.Header>
                            <Toast.Body>{this.state.message}</Toast.Body>
                        </Toast>
                    </ToastContainer>
                    <div className="pageTitle__outer">
                        <h2 className="pageTitle">Income Statistic</h2>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="tableStyle">
                        <Tab.Container defaultActiveKey="revenue">
                            <Nav variant="pills" className="customTabs">
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="revenue"
                                        onClick={() => this.handleTab("Revenue")}
                                    >
                                        Revenue
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="estimates"
                                        onClick={() => this.getExpensesList()}
                                    >
                                        Expenses
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="invoices"
                                        onClick={() => this.handleTab("Invoices")}
                                    >
                                        Invoices
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="revenue">
                                    <div className="table-responsive">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Revenue Date</th>
                                                    <th>Winrate</th>
                                                    <th>Estimates</th>
                                                    <th>Revenue</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.revenueData.reverse().map((items, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {moment(items._id.month, "MM").format("MMMM")}
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                100%
                                                                <div className="tableProgress ms-3">
                                                                    <div className="defaultBar"></div>
                                                                    <div
                                                                        className="completedBar"
                                                                        style={{ width: "100%" }}
                                                                    ></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            ${items.total_estimate.toLocaleString("en-US")}{" "}
                                                        </td>
                                                        <td>
                                                            $
                                                            {(
                                                                items.total_amount - items.total_expenses
                                                            ).toLocaleString("en-US")}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="estimates">
                                    <div className="table-responsive">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Title</th>
                                                    <th>Amount</th>
                                                    <th>Job Title</th>
                                                    <th>Job Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.expensesData.map((items, index) => (
                                                    <tr key={index}>
                                                        <td>{moment(items.createdAt).format("MM/DD/YY")}</td>
                                                        <td>{items.title}</td>
                                                        <td>${parseFloat(items.amount).toLocaleString("en-US")}</td>
                                                        <td>{items.job_id.project_name}</td>
                                                        <td>{items.job_id.status.toUpperCase()}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="d-flex align-items-center showingData">
                                                <Form.Label className="me-2 mb-0">
                                                    showing {this.state.limit} from{" "}
                                                    {this.state.limit * this.state.totalPages}
                                                </Form.Label>
                                                <Form.Select
                                                    defaultValue={this.state.limit}
                                                    onChange={this.selectChange}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={30}>30</option>
                                                    <option value={40}>40</option>
                                                    <option value={50}>50</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={this.state.totalPages}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.paginationHandlig}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                            />
                                        </Col>
                                    </Row>
                                </Tab.Pane>

                                <Tab.Pane eventKey="invoices">
                                    <div className="table-responsive">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Invoice Date</th>
                                                    <th>Winrate</th>
                                                    <th>Estimates</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.revenueData.reverse().map((items, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {moment(items._id.month, "MM").format("MMMM")}
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                0%
                                                                <div className="tableProgress ms-3">
                                                                    <div className="defaultBar"></div>
                                                                    <div
                                                                        className="completedBar"
                                                                        style={{ width: "0%" }}
                                                                    ></div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            ${items.total_estimate.toLocaleString("en-US")}{" "}
                                                        </td>
                                                        <td>
                                                            $
                                                            {(
                                                                items.total_amount - items.total_expenses
                                                            ).toLocaleString("en-US")}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default IncomeExpenses;
