import React,{Component} from 'react';
import { Row, Col, Table, Form, Toast, ToastContainer } from 'react-bootstrap';
import constant from '../constant';
import moment from 'moment-timezone';
import ReactPaginate from 'react-paginate';
import Loader from './partials/Loader';

class UserDeshboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        jobsData : [],
        total_jobs : 0,
        followUp_jobs : 0,
        pending_jobs : 0,
        completed_jobs : 0,
        deleted_jobs : 0,
        ongoing_jobs : 0,
        limit : 10,
        page : 1,
        totalPages : 1,
        showTost : false,
        message : '',
        toggleToast : () => {
            this.setState((prevState,prevProps)=>({
                showTost : !prevState.showTost
            }))
        },
        showLoader : false,
        toggleLoader : () => {
          this.setState((prevState,prevProps)=>({
            showLoader : !prevState.showLoader
          }))
        }
    };
  }
  componentDidMount() {
    this.getJobsDetails();
  }
  getJobsDetails=()=>{
    let apiUrl = `${constant.BASE_API_URL}/jobs/job_details?limit=${this.state.limit}&page=${this.state.page}`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    this.state.toggleLoader();
    fetch(apiUrl, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState((prevState,prevProps)=>({
            jobsData : result.data.leadsData,
            total_jobs : result.data.counttotalLeads,
            followUp_jobs : result.data.countFollowUpLeads,
            pending_jobs : result.data.countPendingLeads,
            completed_jobs : result.data.countcompletedLeads,
            deleted_jobs : result.data.countdeleteLeads,
            ongoing_jobs : result.data.countongoingLeads,
            limit : result.data.limit,
            page : result.data.page,
            totalPages : result.data.totalPages,
            showTost : true,
            message : "Dasboard details."
          }));
          this.state.toggleLoader();
        },
        (error) => {
          this.state.toggleLoader();
          this.setState({
            showTost : true,
            message : "Something went wrong." 
          })          
        }
      );
  }
  convertDate=(date)=>{
      let timeZone = moment.tz.guess();
      return moment(date).tz(timeZone).format("MM/DD/YY");
  }
  paginationHandlig=(data)=>{
       this.setState({
          page: parseInt(data.selected)+1,
       },()=>{
         this.getJobsDetails();
       });
  }
  selectChange = (event) =>{
    this.setState((prevState,prevProps)=>({
        limit: event.target.value,
    }),()=>{
        this.getJobsDetails();
    });
  }
  render() {
    return (
      <Row>
        <Col md={12}>
          { this.state.showLoader && <Loader /> }
          <ToastContainer className="p-3" position="top-end">
            <Toast show={this.state.showTost} onClose={this.state.toggleToast} autohide>
              <Toast.Header>
                <strong className="me-auto">Tradesk</strong>
                <small>just now</small>
              </Toast.Header>
              <Toast.Body>{this.state.message}</Toast.Body>
            </Toast>
          </ToastContainer>
          <div className="pageTitle__outer">
            <h2 className="pageTitle">Dashboard</h2>
          </div>
        </Col>
        <Col sm={6} xl={3}>
          <div className="dashboardDetail__box">
            <div className="detailIcon">
              <img src={"/images/briefcase.svg"} alt="briefcase" />
            </div>
            <p>
              Total Jobs
              <br /> <span>{this.state.total_jobs}</span>
            </p>
          </div>
        </Col>
        <Col sm={6} xl={3}>
          <div className="dashboardDetail__box">
            <div className="detailIcon">
              <img src={"/images/brifecase-tick.svg"} alt="briefcase" />
            </div>
            <p>
              Completed Jobs
              <br /> <span>{this.state.completed_jobs}</span>
            </p>
          </div>
        </Col>
        <Col sm={6} xl={3}>
          <div className="dashboardDetail__box">
            <div className="detailIcon">
              <img src={"/images/brifecase-timer.svg"} alt="briefcase" />
            </div>
            <p>
              Pending Jobs
              <br /> <span>{this.state.pending_jobs}</span>
            </p>
          </div>
        </Col>
        <Col sm={6} xl={3}>
          <div className="dashboardDetail__box">
            <div className="detailIcon">
              <img src={"/images/briefcase.svg"} alt="briefcase" />
            </div>
            <p>
              In Progress Jobs
              <br /> <span>{this.state.ongoing_jobs}</span>
            </p>
          </div>
        </Col>

        <Col md={12}>
          <div className="tableStyle">
            <div className="tableTitle__outer">
              <h2 className="tableTitle">Jobs</h2>
            </div>
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th>Project</th>
                    <th>Description</th>
                    <th>Issue Date</th>
                    <th>Status</th>
                    <th>Paid</th>
                  </tr>
                </thead>
                <tbody>
                    {this.state.jobsData.map((item,index)=>(
                      <tr key={index}>
                        <td>{item.project_name}</td>
                        <td>{item.description}</td>
                        <td>{ this.convertDate(item.createdAt) }</td>
                        <td>{item.status.toUpperCase()}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            37%
                            <div className="tableProgress ms-3">
                              <div className="defaultBar"></div>
                              <div
                                className="completedBar"
                                style={{ width: "37%" }}
                              ></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}                
                </tbody>
              </Table>
            </div>
            <Row>
              <Col md={6}>
                <Form.Group className="d-flex align-items-center showingData">
                  <Form.Label className="me-2 mb-0">
                    showing {this.state.limit} from{" "}
                    { this.state.total_jobs}
                  </Form.Label>
                  <Form.Select defaultValue={this.state.limit} onChange={this.selectChange}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                    <option value={50}>50</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
              <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={this.paginationHandlig}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}

export default UserDeshboard;