import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Form, Toast, ToastContainer } from 'react-bootstrap';
import Loader from '../partials/Loader';
import constants from '../../constant';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom'

function LeadsDetailsById() {
    //state variables
    const [showLoader, setLoader] = useState(false);
    const [showTost, setToast] = useState(false);
    const [state, setState] = useState({
        message : '',
        job_details : {
            additional_images : [],
            sales : [],
            client : [],
            project_name : '',
            type : '',
            created_by : {
                name : '',
                email : '',
                phone_no : '',
                address : {}               
            },
            image : '',
            createdAt : ''
        }
    });

    let params = useParams();

    //set google maps
    let google = window.google || {};
    let autocompleteInput = useRef();

    useEffect(()=>{
        //get details
        getLeadDetails();
    },[params.id]);

    const getLeadDetails = async()=>{
        try {
          setLoader(true);
          let url = `${constants.BASE_API_URL}/jobs/get_leads_by_id/${params.id}`;
          let response = await axios.get(url, {headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
          setLoader(false);
          setToast(true);
          setState((prevState)=>({
              ...prevState,
              message : 'Lead/Job details successfully.',
              job_details : response.data.data.leadsData
          }));

          //set google maps
          let add = response.data.data.leadsData;
          const map =  new google.maps.Map(autocompleteInput.current,{
            center: { lat: add.address.location.coordinates[0], lng: add.address.location.coordinates[1] },
            zoom: 5
          });
          // The marker, positioned at Uluru
          new google.maps.Marker({
            position: { lat: add.address.location.coordinates[0], lng: add.address.location.coordinates[1] },
            map: map,
          });


          console.log(response.data.data)
        } catch (error) {
          setLoader(false);
          setToast(true);
          setState((prevState)=>({
              ...prevState,
              message : 'Something went wrong.'
          }))  
        }
    }

    const formatPhoneNumber=(value)=>{
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
    }
    
    return (
        <Row>
            { showLoader && <Loader /> }
            <ToastContainer className="p-3" position="top-end">
              <Toast show={showTost} onClose={() => setToast(false) } autohide>
                <Toast.Header>
                   <strong className="me-auto">Tradesk</strong>
                   <small>just now</small>
                </Toast.Header>
                <Toast.Body>{state.message}</Toast.Body>
              </Toast>
            </ToastContainer>
            <Col md={12}>
                <div className="pageTitle__outer">
                    <h2 className="pageTitle">{ (state.job_details.type === 'job') ? 'Job' : 'Lead' } Details</h2>
                </div>
            </Col>

            <Col md="12" className="mb-4">
                <div className="whiteBox position-relative">
                    <Form>
                        <Row>
                            <Col md="12">
                                <div className="tableTitle__outer">
                                    <h2 className="tableTitle">{ state.job_details.project_name }</h2>
                                </div>
                            </Col>
                            <Col md="6">
                                <ul className="ProfileDetials jobDetailPage border-0 p-0">
                                    <li><b>Map : </b> 
                                        <p ref={autocompleteInput} style={{height:"350px"}}></p>
                                    </li>
                                    <li><b>Description : </b> 
                                        <p>{state.job_details.description}</p>
                                    </li>
                                </ul>
                            </Col>  
                            <Col md="6">
                                <ul className="ProfileDetials jobDetailPage">
                                    <li><b>Name : </b> 
                                        <p>{state.job_details.created_by.name}</p>
                                    </li>
                                    <li><b>Email : </b> 
                                        <p>{state.job_details.created_by.email}</p>
                                    </li>
                                    <li><b>Phone : </b> 
                                        <p>+1 {formatPhoneNumber(state.job_details.created_by.phone_no)}</p>
                                    </li>
                                    <li><b>Address : </b> 
                                        <p>{state.job_details.created_by.address.street}, {state.job_details.created_by.address.city}, {state.job_details.created_by.address.state}, USA</p>
                                    </li>
                                    <li><b>Date : </b> 
                                        <p>{ moment(state.job_details.created_by.createdAt).format('MM/DD/YYYY')}</p>
                                    </li>
                                    <li><b>Images : </b> 
                                        <p><span className="jobImgList">
                                            { state.job_details.additional_images.length !== 0 && state.job_details.additional_images.map((item,index)=>(
                                                <img src={item.image} alt="user-img" key={index} />
                                            ))}   
                                            { state.job_details.image !== '' && <img src={state.job_details.image} alt="user-img" />}                                         
                                        </span></p>
                                    </li>
                                    <li><b>User : </b> 
                                        <p className="jobUserList">
                                            <span>
                                                <img src={(state.job_details.sales.length !== 0 && state.job_details.sales[0].image !== '') ? state.job_details.sales[0].image : '/images/userImg.png'} alt="user-img" />
                                                <small>{ state.job_details.client.length !== 0 && state.job_details.sales[0].name}</small>
                                            </span>
                                            <span>
                                                <img src={(state.job_details.client.length !== 0 && state.job_details.client[0].image !== '') ? state.job_details.client[0].image : '/images/userImg.png'} alt="user-img" />
                                                <small>{ state.job_details.client.length !== 0 && state.job_details.client[0].name}</small>
                                            </span>
                                        </p>
                                    </li>
                                </ul> 
                            </Col>   
                        </Row>    
                    </Form>
                </div>    
            </Col>
        </Row>
    );
}

export default LeadsDetailsById;