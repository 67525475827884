import React,{Component} from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

class ChangePassword extends Component {
  render() {
    return (
        <Row>
            <Col md={12}>
                <div className="pageTitle__outer">
                    <h2 className="pageTitle">Change Password</h2>
                </div>
            </Col>

            <Col md="12" className="mb-4">
                <Row>
                    <Col md={6}>
                        <div className="whiteBox">
                            <Form> 
                                <div className="mb-4">
                                    <Form.Control type="password" placeholder="New Password" />
                                </div>
                                <div className="mb-4">
                                    <Form.Control type="password" placeholder="Confirm Password" />
                                </div>
                                <div>
                                    <Button variant="primary">Save</Button>
                                </div>
                            </Form>
                        </div> 
                    </Col>
                </Row>   
            </Col>
        </Row>
    );
  }
}

export default ChangePassword;