import React from 'react';
import { Form, Button, Dropdown  } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { set_sidebar_class } from '../../redux/reducer/leadReducer';

function Topbar(){
    //redux dispatch action
    const dispatch = useDispatch();

    return (
        <div className="topBar">
            <div className="openMobileNav" onClick={()=> dispatch(set_sidebar_class('active'))}>
                <img src={'images/menu.svg'} alt="logo" />
            </div>
            <div className="serachTop">
                <Form>
                    <Form.Group className="formControl" controlId="exampleForm.ControlInput1">
                        <Form.Control type="email" placeholder="name@example.com" />
                    </Form.Group>
                    <Button variant="primary"><i className="fa fa-search"></i></Button>
                </Form>
            </div>
            <div className="userDetail">
                <div className="notification me-4">
                    <img src={'/images/notification.svg'} alt="notification" />
                </div>
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <img src={'/images/user-img.svg'} alt="user-img" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/user-details">Profile</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Change Password</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}

export default Topbar;