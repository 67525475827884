import React,{Component} from 'react';
import { Row, Col, Table, Form, Tab, Nav, ToastContainer, Toast } from 'react-bootstrap';
import constant from '../../constant';
import ReactPaginate from 'react-paginate';
import Loader from '../partials/Loader';
import { withRouter } from '../../routers/withRouter';

class JobsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobsData: [],
      total_jobs: 0,
      completed_jobs: 0,
      new_jobs: 0,
      ongoing_jobs: 0,
      limit: 10,
      page: 1,
      status : 'new_jobs',
      totalPages: 1,
      showTost: false,
      message: "",
      toggleToast: () => {
        this.setState((prevState, prevProps) => ({
          showTost: !prevState.showTost,
        }));
      },
      showLoader : false,
      toggleLoader : () => {
          this.setState((prevState,prevProps)=>({
              showLoader : !prevState.showLoader
          }))
      }
    };
  }
  componentDidMount() {
    this.getJobsDetails();
  }
  getJobsDetails = () => {
    this.state.toggleLoader();
    let apiUrl = `${constant.BASE_API_URL}/jobs/recent_job_details?limit=${this.state.limit}&page=${this.state.page}&status=${this.state.status}`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    fetch(apiUrl, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          this.state.toggleLoader();
          console.log(result);
          this.setState((prevState, prevProps) => ({
            jobsData: result.data.jobsData,
            total_jobs: result.data.counttotalLeads,
            completed_jobs: result.data.countcompletedLeads,
            new_jobs: result.data.countnewLeads,
            ongoing_jobs: result.data.countongoingLeads,
            limit: result.data.limit,
            page: result.data.page,
            totalPages: result.data.totalPages,
            showTost: true,
            message: "Jobs details.",
          }));
        },
        (error) => {
          this.state.toggleLoader();
          this.setState({
            showTost: true,
            message: "Something went wrong.",
          });
        }
      );
  };
  paginationHandlig = (data) => {
    this.setState(
      {
        page: parseInt(data.selected) + 1,
      },
      () => {
        this.getJobsDetails();
      }
    );
  };
  selectChange = (event) => {
    this.setState(
      (prevState, prevProps) => ({
        limit: event.target.value,
      }),
      () => {
        this.getJobsDetails();
      }
    );
  };
  proposalCount = (data) =>{
      let proposal_value = 0;
      for (const key in data) {
        proposal_value += parseFloat(data[key].total);
      }
      return(                          
            <span key="proposal_count">${proposal_value.toLocaleString('en-US')}</span>
      )
  }
  handleStatus=(status)=>{
      this.setState({
          status
      },()=>{
          this.getJobsDetails()
      })
  }
  render() {
    return (
      <Row>
        <Col md={12}>
        { this.state.showLoader && <Loader /> }
          <ToastContainer className="p-3" position="top-end">
            <Toast show={this.state.showTost} onClose={this.state.toggleToast} autohide>
            <Toast.Header>
                <strong className="me-auto">Tradesk</strong>
                <small>just now</small>
            </Toast.Header>
            <Toast.Body>{this.state.message}</Toast.Body>
            </Toast>
           </ToastContainer>
          <div className="pageTitle__outer">
            <h2 className="pageTitle">Jobs Details</h2>
          </div>
        </Col>

        <Col md={3}>
          <div className="dashboardDetail__box">
            <div className="detailIcon">
              <img src={"/images/briefcase.svg"} alt="briefcase" />
            </div>
            <p>
              Total Jobs
              <br /> <span>{this.state.total_jobs}</span>
            </p>
          </div>
        </Col>
        <Col md={3}>
          <div className="dashboardDetail__box">
            <div className="detailIcon">
              <img src={"/images/brifecase-tick.svg"} alt="briefcase" />
            </div>
            <p>
              New Jobs
              <br /> <span>{this.state.new_jobs}</span>
            </p>
          </div>
        </Col>
        <Col md={3}>
          <div className="dashboardDetail__box">
            <div className="detailIcon">
              <img src={"/images/brifecase-timer.svg"} alt="briefcase" />
            </div>
            <p>
              Job In Progress
              <br /> <span>{this.state.ongoing_jobs}</span>
            </p>
          </div>
        </Col>
        <Col md={3}>
          <div className="dashboardDetail__box">
            <div className="detailIcon">
              <img src={"/images/dollar.svg"} alt="icon" />
            </div>
            <p>
              Job Completed
              <br /> <span>{this.state.completed_jobs}</span>
            </p>
          </div>
        </Col>

        <Col md={12}>
          <div className="tableStyle">
            <Tab.Container defaultActiveKey="newJobs">
              <Nav variant="pills" className="customTabs">
                <Nav.Item>
                  <Nav.Link eventKey="newJobs" onClick={()=>this.handleStatus('new_jobs')}>New Jobs</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="inProgress" onClick={()=>this.handleStatus('ongoing')}>In Progress</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="completed" onClick={()=>this.handleStatus('completed')}>Completed</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="newJobs">
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>Job Type</th>
                          <th>Client</th>
                          <th>Assigned To</th>
                          <th>Address</th>
                          <th>Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                          {this.state.jobsData.map((item,i)=>(
                            <tr key={i}>
                              <td>{ item.project_name}</td>
                              <td>
                                  {item.client_data.map((items,indexes)=>(                          
                                      <span key={indexes}>{ (indexes ? ', ' : '') +items.name}</span> 
                                  ))}
                              </td>
                              <td>{item.created_by[0].name}</td>
                              <td>{item.address.street}, {item.address.city}, {item.address.state}, {item.address.zipcode}, USA</td>
                              <td>
                                {(item.proposals.length > 0) ? this.proposalCount(item.proposals)  : 'N/A'}
                              </td>
                              <td>
                                  <div className="actionBtn">
                                      <span className="btn" onClick={()=>{this.props.params.navigate(`/lead/lead-details-by-id/${item._id}`)}}>
                                          <img src={'/images/visibility.png'} alt="edit" />
                                      </span>
                                  </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="d-flex align-items-center showingData">
                        <Form.Label className="me-2 mb-0">
                            showing {this.state.limit} from{" "}
                            {this.state.limit * this.state.totalPages}
                        </Form.Label>
                        <Form.Select defaultValue={this.state.limit} onChange={this.selectChange}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                    <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.totalPages}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={this.paginationHandlig}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                        />
                    </Col>
                  </Row>
                </Tab.Pane>

                <Tab.Pane eventKey="inProgress">
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>Job Type</th>
                          <th>Client</th>
                          <th>Assigned To</th>
                          <th>Address</th>
                          <th>Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                          {this.state.jobsData.map((item,i)=>(
                            <tr key={i}>
                              <td>{ item.project_name}</td>
                              <td>
                                  {item.client_data.map((items,indexes)=>(                          
                                      <span key={indexes}>{ (indexes ? ', ' : '') +items.name}</span> 
                                  ))}
                              </td>
                              <td>{item.created_by[0].name}</td>
                              <td>{item.address.street}, {item.address.city}, {item.address.state}, {item.address.zipcode}, USA</td>
                              <td>
                                {(item.proposals.length > 0) ? this.proposalCount(item.proposals)  : 'N/A'}
                              </td>
                              <td>
                                  <div className="actionBtn">
                                      <span className="btn" onClick={()=>{this.props.params.navigate(`/lead/lead-details-by-id/${item._id}`)}}>
                                          <img src={'/images/visibility.png'} alt="edit" />
                                      </span>
                                  </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="d-flex align-items-center showingData">
                        <Form.Label className="me-2 mb-0">
                            showing {this.state.limit} from{" "}
                            {this.state.limit * this.state.totalPages}
                        </Form.Label>
                        <Form.Select defaultValue={this.state.limit} onChange={this.selectChange}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                    <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.totalPages}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={this.paginationHandlig}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                        />
                    </Col>
                  </Row>
                </Tab.Pane>

                <Tab.Pane eventKey="completed">
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>Job Type</th>
                          <th>Client</th>
                          <th>Assigned To</th>
                          <th>Address</th>
                          <th>Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                          {this.state.jobsData.map((item,i)=>(
                            <tr key={i}>
                              <td>{ item.project_name}</td>
                              <td>
                                  {item.client_data.map((items,indexes)=>(                          
                                      <span key={indexes}>{ (indexes ? ', ' : '') +items.name}</span> 
                                  ))}
                              </td>
                              <td>{item.created_by[0].name}</td>
                              <td>{item.address.street}, {item.address.city}, {item.address.state}, {item.address.zipcode}, USA</td>
                              <td>
                                {(item.proposals.length > 0) ? this.proposalCount(item.proposals)  : 'N/A'}
                              </td>
                              <td>
                                  <div className="actionBtn">
                                      <span className="btn" onClick={()=>{this.props.params.navigate(`/lead/lead-details-by-id/${item._id}`)}}>
                                          <img src={'/images/visibility.png'} alt="edit" />
                                      </span>
                                  </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="d-flex align-items-center showingData">
                        <Form.Label className="me-2 mb-0">
                            showing {this.state.limit} from{" "}
                            {this.state.limit * this.state.totalPages}
                        </Form.Label>
                        <Form.Select defaultValue={this.state.limit} onChange={this.selectChange}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                    <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.totalPages}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={this.paginationHandlig}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                        />
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Col>
      </Row>
    );
  }
}

export default withRouter(JobsDetails);