import React,{Component} from 'react';

class Loader extends Component {
  render() {
    return (
        <div className="preloaderWrap" id="loader">
            <div className="preloaderInner">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
  }
}

export default Loader;