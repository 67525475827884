import React from 'react';
import AppRouter from '../routers/AppRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/style.css';


function App() {
  //check token is expired or not if user is already logged In
  if(localStorage.getItem('token')){ 
    let token = localStorage.getItem('token') || 'token';
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    let decoded = JSON.parse(jsonPayload);
    if (decoded.exp < new Date().getTime()/1000) {
      localStorage.removeItem('token');
    }
  }
  return (
    <AppRouter />
  );
}

export default App;
