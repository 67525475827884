import React, { useState } from "react";
import { Nav, Dropdown, NavItem, NavLink } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { set_sidebar_class } from '../../redux/reducer/leadReducer';

function Sidebar() {
    const [activeMenu, setActiveMenu] = useState("");

    //get redux state varaible
    const reduxState = useSelector(state => state.leads);
    const dispatch = useDispatch();

    return (
        <div className={(reduxState.activeMobileSidebar !== '') ? "sideBar__wrapper open" :"sideBar__wrapper"}>
            <div className="siteLogo">
            <span className="closeMobileNav" onClick={()=> dispatch(set_sidebar_class(''))}>
                <img src={"/images/close.svg"} alt="close-icon" />
            </span>
            <h2 className="text-center themeColor">Tradesk</h2>
            </div>
            <div className="sideBar">
            <Nav className="flex-column">
                <Nav.Link
                    as={Link}
                    to="/dashboard"
                    className={activeMenu === "dashboard" ? "active" : ""}
                    onClick={() => {
                        setActiveMenu("dashboard");
                    }}
                >
                <img src={"/images/home-2.svg"} alt="dashboard" /> Dashboard
                </Nav.Link>
                <Nav.Link
                    as={Link}
                    to="/job-details"
                    className={ activeMenu === "job" ? "active" : "" }
                    onClick={() => {
                        setActiveMenu("job");
                    }}
                >
                 <img src={"/images/briefcase-h.svg"} alt="dashboard" /> Jobs
                </Nav.Link>
                <Nav.Link
                    as={Link}
                    to="/lead/details"
                    className={ activeMenu === "lead" ? "active" : ""}
                    onClick={() => {
                        setActiveMenu("lead");
                    }}
                >
                   <img src={"/images/leads.svg"} alt="dashboard" /> Leads
                </Nav.Link>
                <Nav.Link  
                    as={Link}
                    to="/calendar/list"
                    className={ activeMenu === "calendar" ? "active" : "" }
                    onClick={() => {
                        setActiveMenu("calendar");
                    }}
                >
                   <img src={"/images/calendar.svg"} alt="briefcase" /> Calender
                </Nav.Link>
                <Dropdown as={NavItem}>
                <Dropdown.Toggle as={NavLink}>
                    <img src={"/images/dollar-square.svg"} alt="briefcase" />{" "}
                    Revenue
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/income-expenses/details">Income Expenses</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/income-expenses/invoices">Invoices</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/income-expenses/proposal">Proposal</Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
                <Link to="/user/details" className={ activeMenu === "user" ? "nav-link active" : "nav-link" } onClick={() => { setActiveMenu("user"); }}>
                    <img src={"/images/profile-2user.svg"} alt="briefcase" /> User
                </Link>
                <Link to="/terms/privacy-policy" className={ activeMenu === "privacy_policy" ? "nav-link active" : "nav-link" } onClick={() => { setActiveMenu("privacy_policy"); }}>
                    <img src={"/images/privacy_policy.png"} alt="briefcase" /> Privacy Policy
                </Link>
                <Link to="/terms/terms-condition" className={ activeMenu === "terms" ? "nav-link active" : "nav-link" } onClick={() => { setActiveMenu("terms"); }}>
                    <img src={"/images/terms.png"} alt="briefcase" /> Terms and Condition
                </Link>
            </Nav>
            </div>
        </div>
    );
}

export default Sidebar;
