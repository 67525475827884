import React, { useEffect, useState } from "react";
import { Row, Col, Button, Toast, ToastContainer } from "react-bootstrap";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Loader from "./partials/Loader";
import constants from "../constant";
import axios from "axios";

function TermsCondition(){
    //state variables
    const [editor, setEditor] = useState(EditorState.createEmpty()); 
    const [showLoader, setLoader] = useState(false);
    const [showToast, setToast] = useState(false);  
    const [message, setMessage] = useState('');
    
    const onEditorStateChange = ($e) =>{
        setEditor($e);
    }

    useEffect(()=>{
        const getPrivacyPolicy = async() => {
          try {
            setLoader(true);
            let url = `${constants.BASE_API_URL}/terms/get_terms_condition?title=Terms Condition`;
            let response = await axios.get(url, {headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
            const contentBlock = htmlToDraft(response.data.data.description);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditor(editorState)
            }
            setLoader(false);
            setToast(true);
            setMessage('Terms and Condition details.');
          } catch (error) {
            setLoader(false);
            setToast(true);
            setMessage('Something went wrong.');
          }
        }

        getPrivacyPolicy();
    },[]);

    const addPrivacyPolicy = async() =>{
       try {
        setLoader(true);
        let url = `${constants.BASE_API_URL}/terms/addTermsCondition`;
        let data = { title: "Terms Condition", description: draftToHtml(convertToRaw(editor.getCurrentContent())) };
        await axios.post(url, data, {headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
        setLoader(false);
        setToast(true);
        setMessage('Terms and Condition Updated.');
       } catch (error) {
        setLoader(false);
        setToast(true);
        setMessage('Something went wrong.'); 
       }
    }

    return(
        <div>
            {showLoader && <Loader />}
            <ToastContainer className="p-3" position="top-end">
                <Toast show={showToast} onClose={()=> setToast(false)} autohide>
                <Toast.Header>
                    <strong className="me-auto">Tradesk</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
                </Toast>
            </ToastContainer>
            <div className="pageTitle__outer">
                <h2 className="pageTitle">Terms and Condition</h2>
            </div>
            <div className="whiteBox contentPages">
                <Row>
                    <Col md={12}>
                        <Editor
                            editorState={editor}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={($e) => onEditorStateChange($e)}
                        />
                    </Col>
                    <Col md={12}>
                        <div className='d-flex justify-content-center'>
                            <Button variant="primary" onClick={()=>{addPrivacyPolicy()}}>Submit</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default TermsCondition;