import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import PublicRoute  from './PublicRoute';
import UserDeshboard from "../component/Deshboard";
import IncomeExpenses from "../component/income/IncomeExpenses";
import Invoices from "../component/income/Invocies";
import JobsDetails  from "../component/jobs/JobsDetails";
import LeadsDetails from "../component/jobs/LeadsDetails";
import Proposal from "../component/income/Proposal";
import User from "../component/user-info/User";
import UserDetails from "../component/user-info/UserDetails";
import NotFoundPage from "../component/NotFound";
import Login from "../component/Login";
import ChangePassword from "../component/profile/ChangePassword";
import EditProfile from "../component/profile/EditProfile";
import AddLeads from "../component/jobs/AddLeads";
import ForgetPassword from "../component/appComponents/ForgetPassword";
import VerifyEmail from "../component/appComponents/VerifyEmail";
import AddUserForm from "../component/user-info/AddUserForm";
import AddReminder from "../component/calendar/AddReminder";
import ReminderList from "../component/calendar/ReminderList";
import LeadsDetailsById from "../component/jobs/LeadsDetailsById";
import PrivacyPolicy from "../component/PrivacyPolicy";
import TermsCondition from "../component/TermsCondition";


const AppRouter = () => (
  <Router>
    <div>
      <Routes> 
        <Route path="/" element={<PublicRoute />}>
          <Route path="" element={<Login />} />
        </Route>
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="" element={<UserDeshboard />} />
        </Route>
        <Route path="/income-expenses" element={<PrivateRoute />}>
          <Route path="details" element={<IncomeExpenses />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="proposal" element={<Proposal />} />
        </Route>
        <Route path="/job-details" element={<PrivateRoute />}>
          <Route path="" element={<JobsDetails />} />
        </Route>  
        <Route path="/lead" element={<PrivateRoute />}>
          <Route path="details" element={<LeadsDetails />} />
          <Route path="addLeads" element={<AddLeads />} />
          <Route path="lead-details-by-id/:id" element={<LeadsDetailsById />} />
        </Route> 
        <Route path="/calendar" element={<PrivateRoute />}>
          <Route path="addReminder" element={<AddReminder />} />
          <Route path="list" element={<ReminderList />} />
        </Route>
        <Route path="/user" element={<PrivateRoute />}>
          <Route path="details" element={<User />} />
          <Route path="user-details/:id/:type" element={<UserDetails />} />
          <Route path="addUser" element={<AddUserForm />} />
        </Route> 
        <Route path="/terms" element={<PrivateRoute />}>
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-condition" element={<TermsCondition />} />
        </Route>
        <Route path="/profile" element={<PrivateRoute />}>
          <Route path="changePassword" element={<ChangePassword />} />
          <Route path="editprofile" element={<EditProfile />} />
        </Route>     
        <Route path="/forget-password/:token"  element={<ForgetPassword />} />  
        <Route path="/verify-email/:token" element={<VerifyEmail />} />  
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  </Router>
);

export default AppRouter;