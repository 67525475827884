import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Topbar from '../component/partials/Topbar';
import Sidebar from '../component/partials/Sidebar';

const PrivateRoute = () => {
  let auth = localStorage.getItem('token');
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? (
    <div className="siteWrapper">
      <div className="siteOuter">
        <Sidebar />
        <div className="siteRight__part">
          <Topbar />
          <div className="siteRight__content">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  ); 
};

export default PrivateRoute;