import React,{Component} from 'react';
import { Button } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Alert } from 'react-bootstrap';
import * as Yup from "yup";
import Loader from './partials/Loader';
import constant from "../constant";
import axios from "axios";
import { withRouter } from '../routers/withRouter';

class Login extends Component {
  constructor(props){
      super(props);
      this.state = {
        error : false,
        variant : '',
        message : '',
        showLoader : false,
        toggleLoader : () => {
          this.setState((prevState,prevProps)=>({
            showLoader : !prevState.showLoader
          }))
        }
      }
  }
  login=async(values)=>{
    try {
        this.state.toggleLoader();
        let apiUrl = `${constant.BASE_API_URL}/users/login`;
        const response = await axios.post(apiUrl,values); 
        this.state.toggleLoader();
        this.setState((prevState,prevProps)=>({
            error: !prevState.error,
            variant : 'success',
            message : "Logged In."
        }));
        let res = response.data;
        localStorage.setItem('token', res.data.token);
        this.props.params.navigate('/dashboard');        
    } catch (error) {
        console.log(error)
        this.state.toggleLoader();               
        this.setState((prevState,prevProps)=>({
            error: !prevState.error,
            variant : 'danger',
            message : "Something went wrong."
        }));

        setTimeout(()=>{
            this.setState(prevState =>({
                error : !prevState.error
            }))
        },4000); 
    }
  }
  render() {
    const validate = Yup.object({
        email: Yup.string()
          .email("Email is invalid.")
          .required("Email is required."),
        password: Yup.string().required("Password is required."),
    });
    const initialValues  = {
        email: "",
        password: "",
        remmember_me: "",
    };
    return (
        <div className="loginWrap">
            { this.state.showLoader && <Loader /> }
            <div className="loginOuter">
                <h1 className="text-center mb-4 themeColor">Tradesk</h1>
                <div className="login">
                    <div className="pageTitle__outer text-center">
                        <h2 className="pageTitle w-100">Login</h2>
                    </div>
                    {this.state.error && <Alert variant={this.state.variant}>{this.state.message}</Alert> }
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validate}
                        onSubmit={(values, actions) => {
                            this.login(values);
                        }}
                        >
                        <Form> 
                            <div className="form-group mb-4">
                                <Field type="email" name="email" className="form-control" placeholder="name@example.com" />
                                <ErrorMessage name="email" component="div" className="text-danger" />
                            </div>
                            <div className="form-group mb-4">
                                <Field className="form-control" type="password" name="password" placeholder="Password" />
                                <ErrorMessage name="password" component="div" className="text-danger" />
                            </div>
                            <div className="form-group text-center">
                                <Button type='submit' variant="primary">Login</Button>{' '}
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
  }
}

export default withRouter(Login);