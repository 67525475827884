import React,{Component} from 'react';
import { Row, Col, Table, Form, Tab, Nav, ToastContainer, Toast, Modal } from 'react-bootstrap';
import constant from "../../constant";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import Loader from '../partials/Loader';

class Invoices extends Component{
    constructor(props){
        super(props);
        this.state = {
            invoiceData : [],
            limit : 10,
            page : 1,
            totalPages : 1,
            total_invoices : 0,
            total_income : 0,
            paid_invoices : 0,
            total_expenses : 0,
            showTost: false,
            message: "",
            toggleToast: () => {
                this.setState((prevState, prevProps) => ({
                    showTost: !prevState.showTost,
                }));
            },
            showLoader : false,
            toggleLoader : () => {
                this.setState((prevState,prevProps)=>({
                    showLoader : !prevState.showLoader
                }))
            },
            showPdfModel : false,
            togglePdfModel : () => {
                this.setState((prevState,prevProps)=>({
                    showPdfModel : !prevState.showPdfModel
                }))
            },
            pdf_url : ''
        }
    }
    componentDidMount=()=>{
         this.getInvoicesDetails();
    }
    getInvoicesDetails=()=>{
        this.state.toggleLoader();
        let apiUrl = `${constant.BASE_API_URL}/jobs/proposal_list?limit=${this.state.limit}&page=${this.state.page}&type=invoice`;
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        fetch(apiUrl, requestOptions)
          .then((res) => res.json())
          .then(
            (result) => {
              console.log(result);
              this.state.toggleLoader();
              this.setState((prevState,prevProps)=>({
                invoiceData : result.data.proposal_list,
                limit : result.data.limit,
                page : result.data.page,
                totalPages : result.data.totalPages,
                total_invoices : result.data.total_proposal,
                total_income : result.data.total_revenue,
                paid_invoices : 0,
                total_expenses : result.data.total_expense,
                showTost : true,
                message : "Invoice details."
              }));
            },
            (error) => {
             this.state.toggleLoader();
              this.setState({
                showTost : true,
                message : "Something went wrong." 
              })          
            }
          );
    }
    paginationHandlig = (data) => {
        this.setState(
            {
                page: parseInt(data.selected) + 1,
            },
            () => {
                this.getInvoicesDetails();
            }
        );
    };
    selectChange = (event) => {
        this.setState(
            (prevState, prevProps) => ({
                limit: event.target.value,
                page : 1
            }),
            () => {
                this.getInvoicesDetails();
            }
        );
    };
    setPdfUrl=(url)=>{
        this.setState((prevState)=>({
            pdf_url : url
        }),()=>{
            //pdf takes time to load that's why I used loader
            this.state.toggleLoader();
            setTimeout(()=>{
                this.state.toggleLoader();
                this.state.togglePdfModel();
            },10000)
        })
    }
    downloadPdf = (link) =>{
      window.open(link,"_blank");
    }
    render() {
        return (
          <Row>
            <Col md={12}>
              {this.state.showLoader && <Loader />}
              <ToastContainer className="p-3" position="top-end">
                <Toast
                  show={this.state.showTost}
                  onClose={this.state.toggleToast}
                  autohide
                >
                  <Toast.Header>
                    <strong className="me-auto">Tradesk</strong>
                    <small>just now</small>
                  </Toast.Header>
                  <Toast.Body>{this.state.message}</Toast.Body>
                </Toast>
              </ToastContainer>
              <div className="pageTitle__outer">
                <h2 className="pageTitle">Invocies</h2>
              </div>
            </Col>
            <Col md={3}>
              <div className="dashboardDetail__box">
                <div className="detailIcon">
                  <img src={"/images/briefcase.svg"} alt="briefcase" />
                </div>
                <p>
                  Total Invoices
                  <br /> <span>{this.state.total_invoices}</span>
                </p>
              </div>
            </Col>
            <Col md={3}>
              <div className="dashboardDetail__box">
                <div className="detailIcon">
                  <img src={"/images/brifecase-tick.svg"} alt="briefcase" />
                </div>
                <p>
                  Paid Invoices
                  <br /> <span>{this.state.paid_invoices}</span>
                </p>
              </div>
            </Col>
            <Col md={3}>
              <div className="dashboardDetail__box">
                <div className="detailIcon">
                  <img src={"/images/dollar.svg"} alt="briefcase" />
                </div>
                <p>
                  Total Income
                  <br /> <span>${this.state.total_income}</span>
                </p>
              </div>
            </Col>
            <Col md={3}>
              <div className="dashboardDetail__box">
                <div className="detailIcon">
                  <img src={"/images/dollar.svg"} alt="dollar" />
                </div>
                <p>
                  Expenses
                  <br /> <span>${this.state.total_expenses}</span>
                </p>
              </div>
            </Col>

            <Col md={12}>
              <div className="tableStyle">
                <Tab.Container defaultActiveKey="invioces">
                  <Nav variant="pills" className="customTabs">
                    <Nav.Item>
                      <Nav.Link eventKey="invioces">Invoices</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="paidInvoices">Paid Invoices</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="invioces">
                      <div className="table-responsive">
                        <Table>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Assigned To</th>
                              <th>Client</th>
                              <th>Work</th>
                              <th>Status</th>
                              <th>Amount</th>
                              <th>PDF</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.invoiceData.map((items, index) => (
                              <tr key={index}>
                                <td>
                                  {moment(items.createdAt).format("MM/DD/YY")}
                                </td>
                                <td>{items.created_by.name}</td>
                                <td>{items.client_id.name}</td>
                                <td>
                                  {items.items.map((res, indexes) => (
                                    <span key={indexes}>
                                      {(indexes ? ", " : "") + res.title}
                                    </span>
                                  ))}
                                </td>
                                <td>{items.status}</td>
                                <td>${parseFloat(items.total).toLocaleString("en-US")}</td>
                                <td>
                                    <img onClick={()=>{this.setPdfUrl(items.invoice_url)}} className='mr-1' src={'/images/pdf_icon.png'} width={"20px"} alt="icon.png" />
                                    <img onClick={()=>{this.downloadPdf(items.invoice_url)}} src={'/images/download.png'} width={"20px"} alt="icon.png" />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="d-flex align-items-center showingData">
                            <Form.Label className="me-2 mb-0">
                              showing {this.state.limit} from{" "}
                              {this.state.total_invoices}
                            </Form.Label>
                            <Form.Select
                              defaultValue={this.state.limit}
                              onChange={this.selectChange}
                            >
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={40}>40</option>
                              <option value={50}>50</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.totalPages}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={this.paginationHandlig}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                          />
                        </Col>
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="paidInvoices">paidInvoices</Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Col>
            <Modal
              show={this.state.showPdfModel}
              onHide={this.state.togglePdfModel}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>View PDF</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                 <iframe title='invoice_pdf' width="100%" height="600" frameBorder="0" src={`https://docs.google.com/gview?url=${this.state.pdf_url}&embedded=true`}></iframe>
              </Modal.Body>
            </Modal>
          </Row>
        );
    }
}

export default Invoices;