import React,{ useEffect, useRef, useState} from 'react';
import { Row, Col, Button, Modal, Toast, ToastContainer, Alert } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from '../partials/Loader';
//import AddLeadsForm from './AddLeadsForm';
import AddLeadsList from './AddLeadsList';
import constant from "../../constant";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { set_user_details } from '../../redux/reducer/leadReducer';

function AddLeads(props){
    //set loader and toast state
    const [showLoader, setLoader] = useState(false);
    const [showToast, setToast] = useState(false);
    const [showClientModel, setClientModel] = useState(false);

    //state variables
    const [state, setState] = useState({
        message : '',
        method : '',
        error : false,
        variant : '',
        street : '',
        city : '',
        states : '',
        zipcode : '',
        latlong : ''
    });

    let google = window.google || {};

    //get set values from state
    const reduxState = useSelector(state => state.leads);
    const dispatch = useDispatch();

    let autocomplete = null;
    let autocompleteInput = useRef();

    useEffect(()=>{
        autocomplete = new google.maps.places.Autocomplete(autocompleteInput.current, {
            componentRestrictions: { country: "us" },
            fields: ["address_components", "geometry", "icon", "name"],
            strictBounds: false,
            types: ["address"],
        });
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autocomplete.addListener("place_changed", fillInAddress);  
        // eslint-disable-next-line
    },[])

    const fillInAddress = () =>{
        // Get the place details from the autocomplete object.
        const place = autocomplete.getPlace();
        const latitude = place.geometry.location.lat().toFixed(6);
        const longitude = place.geometry.location.lng().toFixed(6);
        let address1 = "";
        let postcode = "";
        //let state = "";

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        // place.address_components are google.maps.GeocoderAddressComponent objects
        // which are documented at http://goo.gle/3l5i5Mr
        for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address1 = `${component.long_name} ${address1}`;
                break;
            }
            case "route": {
                address1 += component.short_name;
                break;
            }
            case "postal_code": 
                setState(prevState=>({
                    ...prevState,
                    zipcode : component.long_name
                }));
                break;
            case "postal_code_suffix": 
                postcode = `${postcode}-${component.long_name}`;
                break;
            case "locality":
                setState(prevState=>({
                    ...prevState,
                    city : component.long_name
                }));
                break;
            case "administrative_area_level_1": 
                setState(prevState=>({
                    ...prevState,
                    states : component.short_name
                }));
                break;
            case "country":
            //document.querySelector("#country").value = component.long_name;
                break;
            default:
            }
        }
        setState(prevState=>({
            ...prevState,
            street : address1,
            latlong : `${latitude},${longitude}`
        }));
    }

    const selectClient = (type) => {
        setState(prevState=>({
            ...prevState,
            method : type
        }))
        setClientModel(!showClientModel);
    }

    const handleError = () => {
        setTimeout(()=>{
            setState(prevState=>({
                ...prevState,
                error : false
            }));
        },3000)
    }

    const AddLeads = async(values,resetForm) =>{
        try {
            if(state.street === '' || state.city === '' || state.states === '' || state.zipcode.length === ''){
                setState(prevState=>({
                    ...prevState,
                    error : true,
                    variant : 'danger',
                    message : "Please enter your full address."
                }));
                handleError();
                return false;
            }
            if(!reduxState.userData._id){
                setState(prevState=>({
                    ...prevState,
                    error : true,
                    variant : 'danger',
                    message : "Please select valid user."
                }));
                handleError();
                return false;
            }
            if(!reduxState.clientData._id || !reduxState.salesData._id){
                setState(prevState=>({
                    ...prevState,
                    error : true,
                    variant : 'danger',
                    message : "Please select valid client/sales person."
                }));
                handleError();
                return false;
            }
    
            let users = `${reduxState.clientData._id},${reduxState.salesData._id}`;
    
            values.state = state.states;
            values.city = state.city;
            values.address = state.street;
            values.zipcode = state.zipcode;
            values.created_by = reduxState.userData._id;
            values.users_assigned = users;
            values.latLong = state.latlong;
    
            let formdata = new FormData();
    
            formdata.append('project_name',values.project_name);
            formdata.append('address',values.address);
            formdata.append('city',values.city);
            formdata.append('state',values.state);
            formdata.append('zipcode',values.zipcode);
            formdata.append('latLong',values.latLong);
            formdata.append('created_by',values.created_by);
            if(values.description !== ''){
                formdata.append('description',values.description);
            }
            formdata.append('source',values.source);
            formdata.append('image',values.image);
            formdata.append('users_assigned',values.users_assigned);
            formdata.append('type',"lead");
    
            setLoader(true);
            let url = `${constant.BASE_API_URL}/jobs/add_leads`;
            const response = await axios.post(url,formdata,{headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
            setState(prevState =>({
                ...prevState,
                error: true,
                variant : 'success',
                message : response.data.message
            })); 
            resetForm();
            setState(prevState=>({
                ...prevState,
                states : '',
                city : '',
                zipcode : '',
                street : ''
            }))
            dispatch(set_user_details({}));
            setLoader(false); 

        } catch (error) {
            setState(prevState =>({
                ...prevState,
                error: true,
                variant : 'danger',
                message : "Something went wrong."
            })); 
            setLoader(false); 
        }
        
    }
    const validate = Yup.object({
        project_name: Yup.string().required("Project name is required.").min(2),
        description: Yup.string(),
        source: Yup.string(),
        image : Yup.mixed()
    });
    const initialValues  = {
        project_name: "",
        description: "",
        source: "",
        image : "",
    };
    return (
        <Row>
            <Col md={12}>
              { showLoader && <Loader /> }
                <ToastContainer className="p-3" position="top-end">
                    <Toast
                        show={showToast}
                        onClose={()=> setToast(false)}
                        autohide
                    >
                        <Toast.Header>
                            <strong className="me-auto">Tradesk</strong>
                            <small>just now</small>
                        </Toast.Header>
                        <Toast.Body>{state.message}</Toast.Body>
                    </Toast>
                 </ToastContainer>
                <div className="pageTitle__outer">
                    <h2 className="pageTitle">Add Leads</h2>
                </div>
            </Col>
            { state.error && <Alert variant={ state.variant}>{state.message}</Alert> }
            <Col md="12" className="mb-4">
                <div className="whiteBox">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validate}
                        onSubmit={(values, actions) => {
                            AddLeads(values,actions.resetForm)
                        }}
                        >
                      <Form>  
                        <Row>
                            <Col md={6}>
                                <div className="mb-4">
                                    <Field className='form-control' name="project_name" type="text" placeholder="Project Name" />
                                    <ErrorMessage name="project_name" component="div" className="text-danger" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-4">
                                    <input className='form-control' ref={autocompleteInput} name="street" type="text" placeholder="Street" />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-4">
                                    <input className='form-control' name="city" type="text" placeholder="City" value={state.city} onChange={($e)=>{ setState(prevState=>({...prevState, city: $e.target.value}))}}/>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-4">
                                    <input className='form-control' name="states" type="text" placeholder="State" value={state.states} onChange={($e)=>{ setState(prevState=>({...prevState, states: $e.target.value}))}} />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-4">
                                    <input className='form-control' name="zipcode" type="text" placeholder="ZipCode" value={state.zipcode} onChange={($e)=>{ setState(prevState=>({...prevState, zipcode: $e.target.value}))}} />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-4">
                                    <Field className='form-control' name="description" as="textarea" rows={5} placeholder="Description" />
                                    <ErrorMessage name="description" component="div" className="text-danger" />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-4">
                                    <label>Add User</label>
                                    <div className="addUserOuter">
                                        <div className="addUserLeads">
                                            <div className="addUserBtn" onClick={()=>{ selectClient('user')}}><img width={19} src={'/images/addIcon.svg'} alt="add-icon" className="me-1" /> 
                                                Add User</div>
                                            {/* <input type="radio" name="created_by" onClick={()=>{ selectClient('user')}} />  */}
                                            <img className="addUserImg" src={(reduxState.userData.image) ? reduxState.userData.image : '/images/userImg.png'} alt="user-img" />
                                            <span>{reduxState.userData.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-4">
                                    <label className="mb-2">Add Client</label>
                                    <div className="addLeads me-5">
                                        <div className="addLeadsLinks">
                                            <a onClick={()=>{ selectClient('client')}}>
                                                <img width={19} src={'/images/addIcon.svg'} alt="add-icon" className="me-1" /> 
                                                Add Client
                                            </a>
                                        </div>
                                    </div>
                                    {reduxState.clientData.name &&
                                        <div className="addUserOuter">
                                            <div className="addUserLeads">
                                                <img className="addUserImg" src={(reduxState.clientData.image) ? reduxState.clientData.image : '/images/userImg.png'} alt="user-img" />
                                                <span>{reduxState.clientData.name}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-4">
                                    <label className="mb-2">Add Sales Person</label>
                                    <div className="addLeads">
                                        <div className="addLeadsLinks">
                                            <a onClick={()=>{ selectClient('sales')}}>
                                                <img width={19} src={'/images/addIcon.svg'} alt="add-icon" className="me-1" /> 
                                                Add Sales Person
                                            </a>
                                        </div>
                                    </div>
                                    <div className="addUserOuter">
                                     {reduxState.salesData.name && <div className="addUserLeads">
                                            <img className="addUserImg" src={(reduxState.salesData.image) ? reduxState.salesData.image : '/images/userImg.png'} alt="user-img" />
                                            <span>{reduxState.salesData.name}</span>
                                        </div>
                                     }
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-4">
                                    <Field className='form-control' name="source" type="text" placeholder="Source" />
                                    <ErrorMessage name="source" component="div" className="text-danger" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-4">
                                    <Field className='form-control' name="image" type="file" placeholder="Image" />
                                </div>
                            </Col>                            
                            <Col md={12}>
                                <div>
                                    <Button variant="primary" type='submit'>Submit</Button>
                                </div>
                            </Col>
                        </Row>  
                      </Form>
                    </Formik>
                </div>    
            </Col>
            <Modal show={showClientModel} onHide={ ()=> setClientModel(false)} backdrop="static" keyboard={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Select {state.method.charAt(0).toUpperCase() + state.method.slice(1)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddLeadsList method={state.method} userId={reduxState.userData._id} />
                </Modal.Body>
            </Modal>
        </Row>
    );
}

export default AddLeads;