import { configureStore } from '@reduxjs/toolkit'
import leadReducer from './reducer/leadReducer';

const store = configureStore({
  reducer: {
    leads: leadReducer
  },
})

store.subscribe(()=>{
    console.log(store.getState())
})

export default store;