import React, { useEffect, useState } from "react";
import Loader from '../partials/Loader';
import constants from '../../constant';
import axios from "axios";
import { useParams } from 'react-router-dom';
import { Alert } from "react-bootstrap";

function VerifyEmail(){
     //state variables
    const [state, setState] = useState({
         variant : '',
         message : ''
    })
    const [showLoader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    //get params from the url
    const params = useParams();

    useEffect(()=>{
        verifyEmail();
        //
    },[]);

   //verify user email
   const verifyEmail = async()=>{
       try {
            setLoader(true);
            const url = `${constants.BASE_APP_URL}/users/verify_email`;
            let data = {
                token : params.token,
            }
            let response = await axios.post(url,data);
            setLoader(false);
            setError(true);
            setState({
                variant : 'success',
                message : response.data.message
            })
            setTimeout(()=>{
                setError(false);
            },3000);  
       } catch (error) {
         setLoader(false);
         setError(true);
         setState({
            variant : 'danger',
            message : error.response.data.message
         });
         setTimeout(()=>{
            setError(false);
         },3000); 
       }
   }

   return(
       <div className="container">
           { showLoader && <Loader /> }
          <div className="row">
             {error && <Alert variant={state.variant}>{state.message}</Alert> }
              <div className="col-md-6">
                 <h3>User Email verified successfully.</h3>                 
              </div>
          </div>
       </div>
   )
}

export default VerifyEmail;