import React,{Component} from 'react';
import { Row, Col, Table, Form, Tab, Nav, ToastContainer, Toast, Modal } from 'react-bootstrap';
import constant from "../../constant";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import Loader from '../partials/Loader';

class Proposal extends Component{
    constructor(props){
        super(props);
        this.state = {
            proposalData: [],
            showTost: false,
            message: "",
            toggleToast: () => {
                this.setState((prevState, prevProps) => ({
                    showTost: !prevState.showTost,
                }));
            },
            page: 1,
            totalPages: 1,
            limit: 10,
            total_proposal : 0,
            total_converted_job : 0,
            sent_revenue : 0,
            converted_revenue : 0,
            showLoader : false,
            toggleLoader : () => {
                this.setState((prevState,prevProps)=>({
                    showLoader : !prevState.showLoader
                }))
            },
            showPdfModel : false,
            togglePdfModel : () => {
                this.setState((prevState,prevProps)=>({
                    showPdfModel : !prevState.showPdfModel
                }))
            },
            pdf_url : ''
        }
    }
    componentDidMount=()=>{
       this.getProposalDetails();
    }
    getProposalDetails=()=>{
        this.state.toggleLoader();
        let apiUrl = `${constant.BASE_API_URL}/jobs/proposal_list?limit=${this.state.limit}&page=${this.state.page}`;
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        fetch(apiUrl, requestOptions)
          .then((res) => res.json())
          .then(
            (result) => {
                this.state.toggleLoader();
              console.log(result)
              this.setState((prevState,prevProps)=>({
                proposalData : result.data.proposal_list,
                total_proposal : result.data.total_proposal,
                total_converted_job : result.data.total_converted_job,
                sent_revenue : result.data.sent_revenue,
                converted_revenue : result.data.total_revenue,
                limit : result.data.limit,
                page : result.data.page,
                totalPages : result.data.totalPages,
                showTost : true,
                message : "Proposal details."
              }));
            },
            (error) => {
              this.state.toggleLoader();
              this.setState({
                showTost : true,
                message : "Something went wrong." 
              })          
            }
          );
    }
    paginationHandlig = (data) => {
        this.setState(
            {
                page: parseInt(data.selected) + 1,
            },
            () => {
                this.getProposalDetails();
            }
        );
    };
    selectChange = (event) => {
        this.setState(
            (prevState, prevProps) => ({
                limit: event.target.value,
                page : 1
            }),
            () => {
                this.getProposalDetails();
            }
        );
    };

    setPdfUrl=(url)=>{
        this.setState((prevState)=>({
            pdf_url : url
        }),()=>{
            //pdf takes time to load that's why I used loader
            this.state.toggleLoader();
            setTimeout(()=>{
                this.state.toggleLoader();
                this.state.togglePdfModel();
            },10000)
        })
    }

    downloadPdf = (link) =>{
        window.open(link,"_blank");
    }

    render() {
        return (
            <Row>
                <Col md={12}>
                   { this.state.showLoader && <Loader /> }
                    <ToastContainer className="p-3" position="top-end">
                        <Toast
                            show={this.state.showTost}
                            onClose={this.state.toggleToast}
                            autohide
                        >
                            <Toast.Header>
                                <strong className="me-auto">Tradesk</strong>
                                <small>just now</small>
                            </Toast.Header>
                            <Toast.Body>{this.state.message}</Toast.Body>
                        </Toast>
                    </ToastContainer>
                    <div className="pageTitle__outer">
                        <h2 className="pageTitle">Proposal</h2>
                    </div>
                </Col>

                <Col md={3}>
                    <div className="dashboardDetail__box">
                        <div className="detailIcon">
                            <img src={'/images/proposal01.svg'} alt="proposal-icon" />
                        </div>
                        <p>Total Proposal<br/> <span>{this.state.total_proposal}</span></p>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="dashboardDetail__box">
                        <div className="detailIcon">
                            <img src={'/images/proposal02.svg'} alt="proposal-icon" />
                        </div>
                        <p>Converted Job<br/> <span>{this.state.total_converted_job}</span></p>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="dashboardDetail__box">
                        <div className="detailIcon">
                            <img src={'/images/dollar.svg'} alt="dollar-icon" />
                        </div>
                        <p>Sent To Revenue<br/> <span>{this.state.sent_revenue}</span></p>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="dashboardDetail__box">
                        <div className="detailIcon">
                            <img src={'/images/dollar.svg'} alt="dollar-icon" />
                        </div>
                        <p>Converted To Revenue<br/> <span>{this.state.converted_revenue}</span></p>
                    </div>
                </Col>

                <Col md={12}>
                    <div className="tableStyle">
                        <Tab.Container defaultActiveKey="proposal">
                            <Nav variant="pills" className="customTabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="proposal">Proposal</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="proposal">
                                    <div className="table-responsive">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Assigned To</th>
                                                    <th>Client</th>
                                                    <th>Work</th>
                                                    <th>Proposal Status</th>
                                                    <th>Proposed Amount</th>
                                                    <th>PDF</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.state.proposalData.map((items,index)=>(
                                                    <tr key={index}>
                                                        <td>{moment(items.createdAt).format("MM/DD/YY")}</td>
                                                        <td>{items.created_by.name}</td>
                                                        <td>{items.client_id.name}</td>
                                                        <td>
                                                            { items.items.map((res,indexes)=>(                          
                                                                <span key={indexes}>{ (indexes ? ', ' : '') +res.title}</span> 
                                                            ))}
                                                        </td>
                                                        <td>{ items.status }</td>
                                                        <td>${parseFloat(items.total).toLocaleString('en-US')}</td>
                                                        <td>
                                                            <img onClick={()=>{this.setPdfUrl(items.invoice_url)}} className='mr-1' src={'/images/pdf_icon.png'} width={"20px"} alt="pdf_link"/>
                                                            <img onClick={()=>{this.downloadPdf(items.invoice_url)}} src={'/images/download.png'} width={"20px"} alt="downloadpdf_link"/>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="d-flex align-items-center showingData">
                                                <Form.Label className="me-2 mb-0">
                                                    showing { this.state.limit } from{" "}
                                                    { this.state.total_proposal }
                                                </Form.Label>
                                                <Form.Select
                                                    defaultValue={this.state.limit}
                                                    onChange={this.selectChange}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={30}>30</option>
                                                    <option value={40}>40</option>
                                                    <option value={50}>50</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={this.state.totalPages}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.paginationHandlig}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                            />
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </Col>
                <Modal
                show={this.state.showPdfModel}
                onHide={this.state.togglePdfModel}
                backdrop="static"
                keyboard={false}
                >
              <Modal.Header closeButton>
                <Modal.Title>View PDF</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                 <iframe title='pdf_proposal' className="pdf" width="100%" height="600" frameBorder="0" src={`https://docs.google.com/gview?url=${this.state.pdf_url}&embedded=true`}></iframe>
              </Modal.Body>
            </Modal>
            </Row>
        );
    }
}

export default Proposal;