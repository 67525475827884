/***
 * reducer for add leads component
 * save following details into redux to use when adding leads
 */
import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    clientData: {},
    salesData : {},
    userData : {},
    activeMobileSidebar : ''
}

const leadsSlice = createSlice({
    name : 'Leads',
    initialState : initialState,
    reducers : {
        add_client_details : (state,action)=>{
            return {
                ...state,
                clientData : {...action.payload }
            }
        },
        add_sales_details : (state,action)=>{
            return {
                ...state,
                salesData : {...action.payload }
            }
        },
        set_user_details : (state,action)=>{
            return {
                clientData: {},
                salesData : {},
                userData : {...action.payload }
            }
        },
        set_sidebar_class : (state,action)=>{
            return{
                ...state,
                activeMobileSidebar : action.payload
            }
        }
    }
})

export const { add_client_details, add_sales_details, set_user_details, set_sidebar_class } = leadsSlice.actions;
               
export default leadsSlice.reducer;