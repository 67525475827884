import React,{Component} from 'react';
import { Row, Col, Table, Form, Toast, ToastContainer } from 'react-bootstrap';
import constant from '../../constant';
import ReactPaginate from 'react-paginate';
import Loader from '../partials/Loader';
import { withRouter } from '../../routers/withRouter';

class LeadsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobsData: [],
      total_jobs: 0,
      followUp_jobs: 0,
      pending_jobs: 0,
      completed_jobs: 0,
      deleted_jobs: 0,
      ongoing_jobs: 0,
      limit: 10,
      page: 1,
      totalPages: 1,
      showTost: false,
      message: "",
      toggleToast: () => {
        this.setState((prevState, prevProps) => ({
          showTost: !prevState.showTost,
        }));
      },
      showLoader : false,
      toggleLoader : () => {
          this.setState((prevState,prevProps)=>({
              showLoader : !prevState.showLoader
          }))
      }
    };
  }
  componentDidMount() {
    this.getLeadsDetails();
  }
  getLeadsDetails = () => {
    this.state.toggleLoader();
    let apiUrl = `${constant.BASE_API_URL}/jobs/job_details?limit=${this.state.limit}&page=${this.state.page}&type=lead`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    fetch(apiUrl, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          this.state.toggleLoader();
          console.log(result);
          this.setState((prevState, prevProps) => ({
            jobsData: result.data.leadsData,
            total_jobs: result.data.counttotalLeads,
            followUp_jobs: result.data.countFollowUpLeads,
            pending_jobs: result.data.countPendingLeads,
            completed_jobs: result.data.countcompletedLeads,
            deleted_jobs: result.data.countdeleteLeads,
            ongoing_jobs: result.data.countongoingLeads,
            limit: result.data.limit,
            page: result.data.page,
            totalPages: result.data.totalPages,
            showTost: true,
            message: "Leads details.",
          }));
        },
        (error) => {
          this.state.toggleLoader();
          this.setState({
            showTost: true,
            message: "Something went wrong.",
          });
        }
      );
  };
  paginationHandlig = (data) => {
    this.setState(
      {
        page: parseInt(data.selected) + 1,
      },
      () => {
        this.getLeadsDetails();
      }
    );
  };
  selectChange = (event) => {
    this.setState(
      (prevState, prevProps) => ({
        limit: event.target.value,
      }),
      () => {
        this.getLeadsDetails();
      }
    );
  };
  render() {
    return (
      <Row>
        <Col md={12}>
        { this.state.showLoader && <Loader /> }
          <ToastContainer className="p-3" position="top-end">
            <Toast show={this.state.showTost} onClose={this.state.toggleToast} autohide>
              <Toast.Header>
                <strong className="me-auto">Tradesk</strong>
                <small>just now</small>
              </Toast.Header>
              <Toast.Body>{this.state.message}</Toast.Body>
            </Toast>
          </ToastContainer>
          <div className="pageTitle__outer">
            <h2 className="pageTitle">Leads Details</h2>
            <div className="filterBtn">
              <button type="button" className="btn btn-primary" onClick={()=> this.props.params.navigate('/lead/addLeads')}>+ Add Leads</button>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div className="dashboardDetail__box">
            <div className="detailIcon">
              <img src={"/images/flag03.svg"} alt="flag-icon" />
            </div>
            <p>
              Total Leads
              <br /> <span>{this.state.total_jobs + this.state.completed_jobs}</span>
            </p>
          </div>
        </Col>
        <Col md={3}>
          <div className="dashboardDetail__box">
            <div className="detailIcon">
              <img src={"/images/flag01.svg"} alt="flag-icon" />
            </div>
            <p>
              Winning Leads
              <br /> <span>{this.state.completed_jobs}</span>
            </p>
          </div>
        </Col>
        <Col md={3}>
          <div className="dashboardDetail__box">
            <div className="detailIcon">
              <img src={"/images/flag02.svg"} alt="flag-icon" />
            </div>
            <p>
              Junk Leads
              <br /> <span>{this.state.deleted_jobs}</span>
            </p>
          </div>
        </Col>
        <Col md={3}>
          <div className="dashboardDetail__box">
            <div className="detailIcon">
              <img src={"/images/document.svg"} alt="document-icon" />
            </div>
            <p>
              Follow Ups
              <br /> <span>{this.state.followUp_jobs}</span>
            </p>
          </div>
        </Col>

        <Col md={12}>
          <div className="tableStyle">
            <div className="tableTitle__outer">
              <h2 className="tableTitle">Leads</h2>
            </div>
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th>Jobs Title</th>
                    <th>Client</th>
                    <th>Assigned To</th>
                    <th>Address</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.jobsData.map((item,index)=>(
                      <tr key={index}>
                          <td>{item.project_name}</td>
                          <td>
                              {item.client.map((items,indexes)=>(                          
                                  <span key={indexes}>{ (indexes ? ', ' : '') +items.name}</span> 
                              ))}
                          </td>
                          <td>{item.created_by.name}</td>
                          <td>{item.address.street}, {item.address.city}, {item.address.state}, {item.address.zipcode}, USA</td>
                          <td>
                              <div className="actionBtn">
                                  <span className="btn" onClick={()=>{this.props.params.navigate(`/lead/lead-details-by-id/${item._id}`)}}>
                                      <img src={'/images/visibility.png'} alt="edit" />
                                  </span>
                              </div>
                          </td>
                      </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Row>
              <Col md={6}>
                <Form.Group className="d-flex align-items-center showingData">
                <Form.Label className="me-2 mb-0">
                    showing {this.state.limit} from{" "}
                    {this.state.limit * this.state.totalPages}
                  </Form.Label>
                  <Form.Select defaultValue={this.state.limit} onChange={this.selectChange}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                    <option value={50}>50</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
              <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={this.paginationHandlig}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}

export default withRouter(LeadsDetails);