import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Toast, ToastContainer } from 'react-bootstrap';
import constants from '../../constant';
import Loader from '../partials/Loader';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

function EditProfile() {
    //state variables 
    const[showLoader, setLoader] = useState(false);
    const[showToast, setToast] = useState(false);
    const[state, setState] = useState({
        message : '',
        user_details : {
            name : '',
            phone_no : '',
            address : {
                street : '',
                state: '',
                city: '',
                postal_code : ''
            },
            image : ''
        }
    });

    useEffect(()=>{
        getProfileData();
    },[]);

    const getProfileData = async()=>{
       try {
         setLoader(true);
         let url = `${constants.BASE_APP_URL}/users/getProfile`;
         let response = await axios.get(url, {headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}`}});
         setLoader(false);
         setToast(true);
         setState({
             message : "User Details.",
             user_details : response.data.data
         })
       } catch (error) {
          setLoader(false);
          setToast(true);
          setState({
              message : "Something went wrong.",
              user_details : {}
          }); 
       }
    }

    const edit_profile = async(values,resetForm)=>{
        try {
            setLoader(true);
            let formdata = new FormData();
    
            formdata.append('name',values.name);
            formdata.append('phone_no',values.phone_no);
            formdata.append('street',values.street);
            formdata.append('city',values.city);
            formdata.append('state',values.state);
            formdata.append('postal_code',values.postal_code);
            formdata.append('image',values.image);

            let url = `${constants.BASE_APP_URL}/users/editProfile`;
            let response = await axios.post(url, formdata, {headers:{
                "Content-Type": "multipart/form-data", 
                'Authorization' : `Bearer ${localStorage.getItem('token')}`}
            });
            console.log(response.data)
            setLoader(false);
            setToast(true);
            setState({
                ...state,
                message : "Profile updated."
            }) 
        } catch (error) {
            setLoader(false);
            setToast(true);
            setState({
                message : "Something went wrong.",
                user_details : {}
            });    
        }
    }

    const validate = Yup.object({
        name: Yup.string().required("Name type is required."),
        phone_no: Yup.string().required("Phone no is required."),
        street: Yup.string().required("street is required."),
        state: Yup.string().required("state is required."),
        city: Yup.string().required("city is required."),
        postal_code: Yup.string().required("postal code is required."),
        image : Yup.mixed()
    });
    
    const initialValues  = {
        name: state.user_details.name,
        phone_no: state.user_details.phone_no,
        street:  (state.user_details.address) ? state.user_details.address.street : '',
        state:  (state.user_details.address) ? state.user_details.address.state : '',
        city:  (state.user_details.address) ? state.user_details.address.city : '',
        postal_code:  (state.user_details.address) ? state.user_details.address.postal_code : '',
        image : ""
    };
    return (
        <Row>
            {showLoader && <Loader />}
            <ToastContainer className="p-3" position="top-end">
            <Toast show={showToast} onClose={()=>setToast(false)} autohide>
              <Toast.Header>
                <strong className="me-auto">Tradesk</strong>
                <small>just now</small>
              </Toast.Header>
              <Toast.Body>{state.message}</Toast.Body>
            </Toast>
          </ToastContainer>
            <Col md={12}>
                <div className="pageTitle__outer">
                    <h2 className="pageTitle">Edit Profile</h2>
                </div>
            </Col>
            <Col md="12" className="mb-4">
                <div className="whiteBox">
                      <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validate}
                        onSubmit={(values, actions) => {
                            edit_profile(values,actions.resetForm)
                        }}
                      >
                      <Form>  
                        <Row>
                            <Col md={6}>
                                <div className="mb-4">
                                    <div className="profileImgOuter text-center">
                                        <img className="profileImg" src={(state.user_details.image !== '') ? state.user_details.image :'/images/img01.svg'} alt="user-img" />
                                        <Field className='form-control' type="file" name='image' />  
                                        <img className="profileEditIcon" src={'/images/edit.svg'} alt="edit-icon" />
                                    </div>   
                                </div> 
                            </Col>
                            <Col md={6}>
                                <div className="mb-4">
                                    <Field name="name" className='form-control' type="text" placeholder="Name" />
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-4">
                                    <input className='form-control' type="email" placeholder="Email" defaultValue={state.user_details.email} readOnly />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-4">
                                    <Field className='form-control' name='phone_no' type="tel" placeholder="Phone" />
                                    <ErrorMessage name="phone_no" component="div" className="text-danger" />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-4">
                                    <Field name="street" className='form-control' type="text" placeholder="Address" />
                                    <ErrorMessage name="street" component="div" className="text-danger" />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-4">
                                    <Field className='form-control' name="state" type="text" placeholder="State"  />
                                    <ErrorMessage name="state" component="div" className="text-danger" />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-4">
                                    <Field className='form-control' name="city" type="text" placeholder="City" />
                                    <ErrorMessage name="city" component="div" className="text-danger" />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-4">
                                    <Field className='form-control'  name="postal_code" type="text" placeholder="Postal Code"/>
                                    <ErrorMessage name="postal_code" component="div" className="text-danger" />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-4">
                                    <input className='form-control' type="text" placeholder="UserType" defaultValue={'Admin'} readOnly />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div>
                                    <Button type='submit' variant="primary">Save</Button>
                                </div>
                            </Col>
                        </Row>   
                      </Form>
                      </Formik>
                </div> 
            </Col>
        </Row>
    );
}

export default EditProfile;