import React,{Component} from 'react';
import { Row, Col, Table, Tab, Nav, Form, Button, Toast, ToastContainer } from 'react-bootstrap';
import constant from "../../constant";
import ReactPaginate from "react-paginate";
import Loader from '../partials/Loader';
import { withRouter } from '../../routers/withRouter';

class User extends Component{
    constructor(props){
        super(props);
        this.state = {
            userData : [],
            clientData : [],
            client_type : 'client',
            limit : 10,
            page : 1,
            total_users : 0, 
            totalPages : 1,
            showTost: false,
            message: "",
            toggleToast: () => {
                this.setState((prevState, prevProps) => ({
                    showTost: !prevState.showTost,
                }));
            },
            showLoader : false,
            toggleLoader : () => {
                this.setState((prevState,prevProps)=>({
                    showLoader : !prevState.showLoader
                }))
            }
        }
    }
    componentDidMount=()=>{
       this.getUsersDetails();
    }
    getUsersDetails=()=>{
        this.state.toggleLoader();
        let apiUrl = `${constant.BASE_API_URL}/users/users_list?limit=${this.state.limit}&page=${this.state.page}`;
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        fetch(apiUrl, requestOptions)
          .then((res) => res.json())
          .then(
            (result) => {
              this.state.toggleLoader();
              console.log(result)
              this.setState((prevState,prevProps)=>({
                userData : result.data.userData,
                limit : result.data.limit,
                page : result.data.page,
                total_users : result.data.totalUsers, 
                totalPages : result.data.totalPages,
                showTost : true,
                message : "Users details."
              }));
            },
            (error) => {
              this.state.toggleLoader();
              this.setState({
                showTost : true,
                message : "Something went wrong." 
              })          
            }
        );
    }
    paginationHandlig = (data,type) => {
        this.setState({
            page: parseInt(data.selected) + 1,
        },() => {
            if(type === 'users'){
              this.getUsersDetails();
            }else{
              this.getClientDetails();
            }
        });
    };
    selectChange = (event,type) => {
        this.setState((prevState, prevProps) => ({
            limit: event.target.value,
            page : 1,
            totalPages : 1
        }),() => {
            if(type === 'users'){
              this.getUsersDetails();
            }else{
              this.getClientDetails();
            }
        });
    };
    getClientDetails=()=>{
        this.state.toggleLoader();
        let apiUrl = `${constant.BASE_API_URL}/users/client_list?limit=${this.state.limit}&page=${this.state.page}&type=${this.state.client_type}`;
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        fetch(apiUrl, requestOptions)
          .then((res) => res.json())
          .then(
            (result) => {
              this.state.toggleLoader();
              console.log(result)
              this.setState((prevState,prevProps)=>({
                clientData : result.data.client,
                limit : result.data.limit,
                page : result.data.page,
                total_users : result.data.totalClient, 
                totalPages : result.data.totalPages,
                showTost : true,
                message : "Client details."
              }));
            },
            (error) => {
              this.state.toggleLoader();
              this.setState({
                showTost : true,
                message : "Something went wrong." 
              })          
            }
        ); 
    }
    getDataAccTab=(type, client_type)=>{
        this.setState({
            limit : 10,
            page : 1,
            total_users : 0,
            client_type 
        },()=>{
            if(type === 'users'){
                this.getUsersDetails();
            }else{
                this.getClientDetails()
            }
        })
    }
    formatPhoneNumber=(value)=>{
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;
    }
    userNavigate=(id,type)=>{
       this.props.params.navigate(`/user/user-details/${id}/${type}`);
    }
    render() {
        return (
            <Row>
                <Col md={12}>
                     { this.state.showLoader && <Loader /> }
                     <ToastContainer className="p-3" position="top-end">
                        <Toast
                            show={this.state.showTost}
                            onClose={this.state.toggleToast}
                            autohide
                        >
                            <Toast.Header>
                                <strong className="me-auto">Tradesk</strong>
                                <small>just now</small>
                            </Toast.Header>
                            <Toast.Body>{this.state.message}</Toast.Body>
                        </Toast>
                      </ToastContainer>
                    <div className="pageTitle__outer">
                        <h2 className="pageTitle">User</h2>
                        <div className="filterBtn">
                            <Form.Group className="me-4">
                                <Form.Select>
                                <option>Filter</option>
                                <option>Filter02</option>
                                <option>Filter03</option>
                                <option>Filter04</option>
                                </Form.Select>
                            </Form.Group>
                            <Button variant="primary" onClick={()=>this.props.params.navigate('/user/addUser')}>+ Add User</Button>
                        </div>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="tableStyle">
                        <Tab.Container defaultActiveKey="users">
                            <Nav variant="pills" className="customTabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="users" onClick={()=>this.getDataAccTab('users', 'client')}>AppUsers</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="projectManager" onClick={()=>this.getDataAccTab('client', 'client')}>Clients</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="employees" onClick={()=>this.getDataAccTab('client', 'sales')}>Sales Persons</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="users">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email Address</th>
                                                <th>Phone</th>
                                                <th>Address</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.state.userData.map((items,index) => (
                                                <tr key={index}>
                                                <td onClick={()=>{this.userNavigate(items._id,'user')}}>{ items.name }</td>
                                                <td>{ items.email }</td>
                                                <td>{ this.formatPhoneNumber(items.phone_no) }</td>
                                                <td>{ items.address.street }, {items.address.city}, {items.address.state}, {items.address.postal_code}, {items.address.country }</td>
                                                <td>
                                                    <div className="actionBtn">
                                                        {/* <a className="btn">
                                                            <img src={'/images/phone.svg'} alt="phone" />
                                                        </a>
                                                        <a className="btn">
                                                            <img src={'/images/message.svg'} alt="message" />
                                                        </a> */}
                                                        <span className="btn" onClick={()=>{this.userNavigate(items._id,'user')}}>
                                                            <img src={'/images/visibility.png'} alt="edit" />
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            )) }
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="d-flex align-items-center showingData">
                                                <Form.Label className="me-2 mb-0">
                                                    showing { this.state.limit } from{" "}
                                                    { this.state.total_users }
                                                </Form.Label>
                                                <Form.Select
                                                    defaultValue={this.state.limit}
                                                    onChange={(event)=>this.selectChange(event,'users')}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={30}>30</option>
                                                    <option value={40}>40</option>
                                                    <option value={50}>50</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={this.state.totalPages}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={3}
                                                onPageChange={(event)=>this.paginationHandlig(event,'users')}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                            />
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="projectManager">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email Address</th>
                                                <th>Phone</th>
                                                <th>Address</th>
                                                {/* <th>Trade</th> */}
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.state.clientData.map((items,index) => (
                                                <tr key={index}>
                                                <td onClick={()=>{this.userNavigate(items._id,'client')}}>{ items.name }</td>
                                                <td>{ items.email }</td>
                                                <td>{ this.formatPhoneNumber(items.phone_no) }</td>
                                                <td>{ items.address.street }, {items.address.city}, {items.address.state}, {items.address.postal_code}, USA</td>
                                                {/* <td>{ (items.trade) ? items.trade : 'N/A' }</td> */}
                                                <td>
                                                    <div className="actionBtn">
                                                        {/* <a className="btn">
                                                            <img src={'/images/phone.svg'} alt="phone" />
                                                        </a>
                                                        <a className="btn">
                                                            <img src={'/images/message.svg'} alt="message" />
                                                        </a> */}
                                                        <span className="btn" onClick={()=>{this.userNavigate(items._id,'client')}}>
                                                            <img src={'/images/visibility.png'} alt="edit" />
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            )) }
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="d-flex align-items-center showingData">
                                                <Form.Label className="me-2 mb-0">
                                                    showing { this.state.limit } from{" "}
                                                    { this.state.total_users }
                                                </Form.Label>
                                                <Form.Select
                                                    defaultValue={this.state.limit}
                                                    onChange={(event)=>this.selectChange(event,'client')}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={30}>30</option>
                                                    <option value={40}>40</option>
                                                    <option value={50}>50</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={this.state.totalPages}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={3}
                                                onPageChange={(event)=>this.paginationHandlig(event,'client')}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                            />
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="employees">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email Address</th>
                                                <th>Phone</th>
                                                <th>Address</th>
                                                <th>Trade</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.state.clientData.map((items,index) => (
                                                <tr key={index}>
                                                <td  onClick={()=>{this.userNavigate(items._id,'client')}}>{ items.name }</td>
                                                <td>{ items.email }</td>
                                                <td>{ this.formatPhoneNumber(items.phone_no) }</td>
                                                <td>{ items.address.street }, {items.address.city}, {items.address.state}, {items.address.postal_code}, USA</td>
                                                <td>{ (items.trade) ? items.trade : 'N/A' }</td>
                                                <td>
                                                    <div className="actionBtn">
                                                        {/* <a className="btn">
                                                            <img src={'/images/phone.svg'} alt="phone" />
                                                        </a>
                                                        <a className="btn">
                                                            <img src={'/images/message.svg'} alt="message" />
                                                        </a> */}
                                                        <span className="btn" onClick={()=>{this.userNavigate(items._id,'client')}}>
                                                            <img src={'/images/visibility.png'} alt="edit" />
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            )) }
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="d-flex align-items-center showingData">
                                                <Form.Label className="me-2 mb-0">
                                                    showing { this.state.limit } from{" "}
                                                    { this.state.total_users }
                                                </Form.Label>
                                                <Form.Select
                                                    defaultValue={this.state.limit}
                                                    onChange={(event)=>this.selectChange(event,'client')}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={30}>30</option>
                                                    <option value={40}>40</option>
                                                    <option value={50}>50</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={this.state.totalPages}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={3}
                                                onPageChange={(event)=>this.paginationHandlig(event,'client')}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                            />
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </Col>
            </Row>
        ); 
    }
}

export default withRouter(User);