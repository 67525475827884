import React, { useState } from "react";
import { Row, Col, Button, Alert, Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";
import AddLeadsList from '../jobs/AddLeadsList';
import Loader from "../partials/Loader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import constant from "../../constant";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddReminder(){
  //set loader and toast state
  const [showLoader, setLoader] = useState(false);
  const [showClientModel, setClientModel] = useState(false);  
  const [startDate, setStartDate] = useState(new Date());
  const [state, setState] = useState({
      error : false,
      variant : '',
      message : ''
  });

  //redux state variable
  const reduxState = useSelector(state => state.leads);

  const handleError = () => {
    setTimeout(()=>{
        setState(prevState=>({
            ...prevState,
            error : false
        }));
    },3000)
   }

  const AddReminder = async(values, resetForm) => {
      try {
        if(!reduxState.userData._id){
            setState(prevState=>({
                ...prevState,
                error : true,
                variant : 'danger',
                message : "Please select valid user."
            }));
            handleError();
            return false;
        }
        
        values.created_by = reduxState.userData._id;
        values.type = "lead";
        values.timezone = moment.tz.guess();
        if(values.description === ''){
            delete values.description;
        }
        setLoader(true);
        let url = `${constant.BASE_API_URL}/reminder/addReminder`;
        let response = await axios.post(url, values, {headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}`}});
        setLoader(false);
        resetForm();
        setState({
            error : true,
            variant : 'success',
            message : response.data.message
        });
      } catch (error) {
          console.log(error.response.data)
        setState(prevState =>({
            ...prevState,
            error: true,
            variant : 'danger',
            message : "Something went wrong."
        })); 
        setLoader(false); 
      }
  }

  const validate = Yup.object({
    remainder_type: Yup.string().required("Remainder type is required."),
    dateTime : Yup.date().required("Date time type is required.").min(moment().subtract(1, 'day'),"Date should be greater then or equal to current date."),
    description: Yup.string().optional("description is required.")
  });

 const initialValues  = {
    remainder_type: "",
    dateTime: "",
    description: ""
 };

  return(
      <Row>
          { showLoader && <Loader />}
          <Col md={12}>
            <div className="pageTitle__outer">
                <h2 className="pageTitle">Add New Reminder</h2>
            </div>
          </Col>
          { state.error && <Alert variant={ state.variant}>{state.message}</Alert> }
          <Col md="12" className="mb-4">
            <Formik
               initialValues={initialValues}
               validationSchema={validate}
               onSubmit={(values, actions) => {
                  AddReminder(values,actions.resetForm)
               }}
            >
            {({setFieldValue})=>(
              <Form>
                <Row>                    
                    <Col md={6}>
                        <div className="mb-4">
                            <Field as="select" className="form-control" name="remainder_type">
                                <option>Would you like to setup</option>
                                <option value="phone">A Phone Call Reminder?</option>
                                <option value="appointment">An Appointment Reminder?</option>
                            </Field>
                            <ErrorMessage name="remainder_type" component="div" className="text-danger" />
                        </div>
                    </Col> 
                    <Col md={6}>
                        <div className="mb-4">
                            {/* <Field className='form-control' name="dateTime" type="datetime-local" placeholder="DateTime" /> */}
                            <DatePicker
                                selected={startDate} 
                                onChange={(date) => {setFieldValue('dateTime', date);setStartDate(date);}} 
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                className="form-control"
                                showTimeInput
                             />
                            <ErrorMessage name="dateTime" component="div" className="text-danger" />
                        </div>
                    </Col> 
                    <Col md={12}>
                        <div className="mb-4">
                            <label>Add User</label>
                            <div className="addUserOuter">
                                <div className="addUserLeads">
                                    <div className="addUserBtn" onClick={()=>{ setClientModel(true)}}><img width={19} src={'/images/addIcon.svg'} alt="add-icon" className="me-1" /> 
                                        Add User</div>
                                    <img className="addUserImg" src={(reduxState.userData.image) ? reduxState.userData.image : '/images/userImg.png'} alt="user-img" />
                                    <span>{reduxState.userData.name}</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="mb-4">
                            <Field as="textarea" className='form-control' name="description" rows={5} placeholder="Description" />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                        </div>
                    </Col>                   
                    <Col md={12}>
                        <div className='d-flex justify-content-center'>
                            <Button variant="primary" type="submit">Submit</Button>
                        </div>
                    </Col>
                </Row>  
              </Form>
            )}
            </Formik>
          </Col>
          <Modal show={showClientModel} onHide={ ()=> setClientModel(false)} backdrop="static" keyboard={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Select User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AddLeadsList method="user" userId="123389" />
            </Modal.Body>
          </Modal>
      </Row>
  )
}

export default AddReminder;