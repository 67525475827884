import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import Loader from '../partials/Loader';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import constant from "../../constant";

function AddUserForm() {
    //set loader and toast state
    const [showLoader, setLoader] = useState(false);

    //state variables
    const [state, setState] = useState({
        street : '',
        city : '',
        states : '',
        zipcode : '',
        latlong : '',
        error : false,
        message : '',
        variant : ''
    });

    //define variables for google autocomplete api
    let google = window.google || {};
    let autocomplete = null;
    let autocompleteInput = useRef();

    useEffect(()=>{
        autocomplete = new google.maps.places.Autocomplete(autocompleteInput.current, {
            componentRestrictions: { country: "us" },
            fields: ["address_components", "geometry", "icon", "name"],
            strictBounds: false,
            types: ["address"],
        });
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        autocomplete.addListener("place_changed", fillInAddress); 
       // console.log(autocomplete)
    },[]);

    const fillInAddress = () =>{
        // Get the place details from the autocomplete object.
        const place = autocomplete.getPlace();
        const latitude = place.geometry.location.lat().toFixed(6);
        const longitude = place.geometry.location.lng().toFixed(6);
        let address1 = "";
        let postcode = "";
        //let state = "";

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        // place.address_components are google.maps.GeocoderAddressComponent objects
        // which are documented at http://goo.gle/3l5i5Mr
        for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
            case "street_number": {
                address1 = `${component.long_name} ${address1}`;
                break;
            }
            case "route": {
                address1 += component.short_name;
                break;
            }
            case "postal_code": 
                setState(prevState=>({
                    ...prevState,
                    zipcode : component.long_name
                }));
                break;
            case "postal_code_suffix": 
                postcode = `${postcode}-${component.long_name}`;
                break;
            case "locality":
                setState(prevState=>({
                    ...prevState,
                    city : component.long_name
                }));
                break;
            case "administrative_area_level_1": 
                setState(prevState=>({
                    ...prevState,
                    states : component.short_name
                }));
                break;
            case "country":
            //document.querySelector("#country").value = component.long_name;
                break;
            default:
            }
        }
        setState(prevState=>({
            ...prevState,
            street : address1,
            latlong : `${latitude},${longitude}`
        }));
    }

    const handleError = () => {
        setTimeout(()=>{
            setState(prevState=>({
                ...prevState,
                error : false
            }));
        },3000)
    }

    const AddUser = async(values, resetForm) => {
        try {
            if(state.street === '' || state.city === '' || state.states === '' || state.zipcode.length !== 5){
                setState(prevState=>({
                    ...prevState,
                    error : true,
                    variant : 'danger',
                    message : "Please enter your full address."
                }));
                handleError();
                return false;
            }
            setLoader(true);

            values.state = state.states;
            values.city = state.city;
            values.address = state.street;
            values.postal_code = state.zipcode;
            values.latLong = state.latlong;

            let url = `${constant.BASE_API_URL}/users/addUser`;
            let response = await axios.post(url, values, {headers:{ 'Authorization' : `Bearer ${localStorage.getItem('token')}` }});
            setLoader(false);
            resetForm();

            setState({
                states : '',
                city : '',
                zipcode : '',
                street : '',
                error : true,
                variant : 'success',
                message : response.data.message
            })
        } catch (error) {
            setLoader(false);
            setState({
                ...state,
                error : true,
                variant : 'danger',
                message : error.response.data.message
            }); 
        }
    }

    const validate = Yup.object({
        name: Yup.string().required("Name is required.").min(4),
        email: Yup.string().email('Please enter valid email.').required("Email is required."),
        password: Yup.string().required("Password is required.").matches(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,'Password should be combination of one uppercase , one lower case, one special char, one digit and min 8 , max 20 char long'),
        confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match.').required("Confirm Password is required."),
    });
    const initialValues  = {
        name: "",
        email: "",
        password: "",
        confirm_password : "",
    };

    return (
        <Row>
            {showLoader && <Loader />}
            <Col md={12}>
                <div className="pageTitle__outer">
                    <h2 className="pageTitle">Add New User</h2>
                </div>
            </Col>
            { state.error && <Alert variant={ state.variant}>{state.message}</Alert> }
            <Col md="12" className="mb-4">
                <div className="whiteBox">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validate}
                      onSubmit={(values, actions) => {
                          AddUser(values,actions.resetForm)
                      }}
                    >
                        <Form> 
                            <Row>
                                <Col md={6}>
                                    <div className="mb-4">
                                        <Field className='form-control' name="name" type="text" placeholder="Name" />
                                        <ErrorMessage name="name" component="div" className="text-danger" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-4">
                                        <Field className='form-control' name="email" type="email" placeholder="Email" />
                                        <ErrorMessage name="email" component="div" className="text-danger" />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-4">
                                        <input className='form-control' ref={autocompleteInput} name="street" type="text" placeholder="Street" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-4">
                                        <input className='form-control' name="city" type="text" placeholder="City" value={state.city} onChange={($e)=>{ setState(prevState=>({...prevState, city: $e.target.value}))}}/>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-4">
                                        <input className='form-control' name="states" type="text" placeholder="State" value={state.states} onChange={($e)=>{ setState(prevState=>({...prevState, states: $e.target.value}))}} />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-4">
                                        <input className='form-control' name="zipcode" type="text" placeholder="ZipCode" value={state.zipcode} onChange={($e)=>{ setState(prevState=>({...prevState, zipcode: $e.target.value}))}} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-4">
                                        <Field className='form-control' name="password" type="password" placeholder="Password" />
                                        <ErrorMessage name="password" component="div" className="text-danger" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-4">
                                        <Field className='form-control' name="confirm_password" type="password" placeholder="Comfirm Password" />
                                        <ErrorMessage name="confirm_password" component="div" className="text-danger" />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className='d-flex justify-content-center'>
                                        <Button variant="primary" type="submit">Submit</Button>
                                    </div>
                                </Col>
                            </Row>  
                        </Form>
                    </Formik>
                </div>    
            </Col>
        </Row>
    );
}

export default AddUserForm;