import React,{useEffect, useState} from 'react';
import { Row, Col, Form, Table, Toast, ToastContainer } from 'react-bootstrap';
import Loader from '../partials/Loader';
import constant from "../../constant";
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { add_client_details, add_sales_details, set_user_details  } from '../../redux/reducer/leadReducer';

function AddLeadsList(props) {
    const [state,setState] = useState({
        userData : [],
        limit : 10,
        page : 1,
        totalData : 0, 
        totalPages : 0,
        message : ''
    });

    //set loader and toast state
    const [showLoader, setLoader] = useState(false);
    const [showToast, setToast] = useState(false);

    //dispatch for redux state
    const dispatch = useDispatch();
    //get set values from state
    const reduxState = useSelector(state => state.leads);
    
    useEffect(() => {
        if(props.method === 'user'){
            getUsersDetails();
        }else{
            getClientDetails();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 

  const getUsersDetails=()=>{
    setLoader(true);
    let apiUrl = `${constant.BASE_API_URL}/users/users_list?limit=${state.limit}&page=${state.page}`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    fetch(apiUrl, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setLoader(false);
          //add this variable to the array to manage the checkboxes
          if(result.data.userData.length > 0){
            result.data.userData.forEach((element) => {
              element.isSelected = false;
              //set if its already selected
              if(reduxState.userData._id === element._id){
                element.isSelected = reduxState.userData.isSelected
              }
            });
          }
          console.log(result)
          setState({
            userData : result.data.userData,
            limit : result.data.limit,
            page : result.data.page,
            totalData : result.data.totalUsers, 
            totalPages : result.data.totalPages
          });
        },
        (error) => {
          setLoader(false);
          setState(prevState=>({
            ...prevState,
            message : "Something went wrong." 
          }))          
        }
    );
  }
  const getClientDetails=()=>{
    setLoader(true);
    let apiUrl = `${constant.BASE_API_URL}/users/userClientById/${props.userId}?limit=${state.limit}&page=${state.page}&type=${props.method}`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    fetch(apiUrl, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
         setLoader(false);
         //add this variable to the array to manage the checkboxes
         if(result.data.userData.length > 0){
            result.data.userData.forEach((element) => {
              element.isSelected = false;
              //get array according to method
              let selectedObj = (props.method === 'client') ? reduxState.clientData : reduxState.salesData;
              //set if its already selected
              if(selectedObj._id === element._id){
                element.isSelected = reduxState.userData.isSelected
              }
            });
         }
          console.log(result)
          setState({
            userData : result.data.userData,
            limit : result.data.limit,
            page : result.data.page,
            totalData : result.data.totalData, 
            totalPages : result.data.totalPages
          });
        },
        (error) => {
          setLoader(false);
          setState(prevState=>({
            ...prevState,
            message : "Something went wrong." 
          }));          
        }
    );
  }
  const paginationHandlig = (data) => {
    setState(prevState=>({
        ...prevState,
        page: parseInt(data.selected) + 1,
      }));
    if(props.method === 'user'){
        getUsersDetails();
    }else{
        getClientDetails();
    }
  };
  const selectChange = (event) => {
    setState(prevState=>({
        ...prevState,
        limit: event.target.value,
    }));
    if(props.method === 'user'){
        getUsersDetails();
    }else{
        getClientDetails();
    }
  };

  const formatPhoneNumber=(value)=>{
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const handleCheckbox = ($event,item) => {
    isSelected(item)
    if(props.method === 'client'){
      dispatch(add_client_details(item));
    }else if(props.method === 'sales'){
      dispatch(add_sales_details(item))
    }else{
       dispatch(set_user_details(item))
    }    
  }

  const isSelected = (item) =>{
    let updatedUserData = state.userData.map(val=>{
      if(val._id === item._id){
          val.isSelected = !val.isSelected
          return {...val};
      }
        val.isSelected = false;
        return {...val};
    });
    setState(prevState => ({ ...prevState, userData : [...updatedUserData] }));
  }
    return (
        <Row>
            <Col md={12}>
            { showLoader && <Loader /> }
            <ToastContainer className="p-3" position="top-end">
                    <Toast
                        show={showToast}
                        onClose={()=>{setToast(false)}}
                        autohide
                    >
                        <Toast.Header>
                            <strong className="me-auto">Tradesk</strong>
                            <small>just now</small>
                        </Toast.Header>
                        <Toast.Body>{state.message}</Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="tableStyle">
                    <div className="tableTitle__outer">
                        <h2 className="tableTitle">{props.method.charAt(0).toUpperCase() + props.method.slice(1)} Details</h2>
                        <div className="addLeadsForm">
                            { (props.method !== 'user') && <button className="btn btn-primary">Add New</button> }
                        </div>
                    </div>
                    <div className="table-responsive">
                        <Table>
                            <thead>
                            <tr>
                                <th>All</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Address</th>
                            </tr>
                            </thead>
                            <tbody>
                                { state.userData.map((item,index)=>(
                                    <tr key={index}>
                                        <td>
                                          <input 
                                              type={(props.method === 'user') ? 'radio' : 'radio'} 
                                              name="addLeads" 
                                              onChange={(e)=>handleCheckbox(e,item)} 
                                              checked={item.isSelected}
                                          />
                                        </td>
                                        <td><img className="addleadImg" src={(item.image) ? item.image : '/images/userImg.png'} alt="user-img" /></td>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{ formatPhoneNumber(item.phone_no) }</td>
                                        <td>{ item.address.street }, {item.address.city}, {item.address.state}, {item.address.postal_code}, USA</td>
                                    </tr> 
                                )) }            
                            </tbody>
                        </Table>
                    </div>
                    <Row>
                    <Col md={6}>
                    <Form.Group className="d-flex align-items-center showingData">
                        <Form.Label className="me-2 mb-0">
                            showing {state.limit} from{" "}
                            {state.totalData}
                        </Form.Label>
                        <Form.Select defaultValue={state.limit} onChange={selectChange}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                        </Form.Select>
                    </Form.Group>
                    </Col>
                    <Col md={6}>
                    <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={state.totalPages}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={paginationHandlig}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                        />
                    </Col>
                </Row>
                </div>
            </Col>
        </Row>
    );
}

export default AddLeadsList;