import axios from "axios";
import React, { Component } from "react";
import { Row, Col, Table, Form, Tab, Nav, Toast, ToastContainer, Modal } from "react-bootstrap";
import constants from "../../constant";
import Loader from "../partials/Loader";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import { withRouter } from '../../routers/withRouter';

class UserDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      client_details: {},
      openJobsCount: 0,
      proposalCount: 0,
      totalJobsCount: 0,
      totalLeadsCount: 0,
      userData : [],
      showTost : false,
      showLoader : false,
      user_jobs_details : [],
      job_type : 'job',
      page : 1,
      limit : 10,
      totalPages : 1,
      totalData : 0,
      message : '',
      toggleToast: () => {
        this.setState((prevState, prevProps) => ({
            showTost: !prevState.showTost,
        }));
      },
      toggleLoader : () => {
          this.setState((prevState,prevProps)=>({
              showLoader : !prevState.showLoader
          }))
      },
      showPdfModel : false,
      togglePdfModel : () => {
          this.setState((prevState,prevProps)=>({
              showPdfModel : !prevState.showPdfModel
          }))
      },
      pdf_url : ''
    }
  }

  componentDidMount(){
    this.getRevenueDetails();
    //this.getJobDetails();
  }

  getRevenueDetails = async() => {
    try {
        const params = this.props.params.queryParams;
        this.state.toggleLoader(); //show loader
        let url = `${constants.BASE_API_URL}/users/userStatics/${params.id}/${params.type}`;
        let response = await axios.get(url,{
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        this.state.toggleLoader();
        let result = response.data;
        this.setState((prevState)=>({
            showLoader : false,
            client_details: result.data.client_details,
            openJobsCount: result.data.openJobsCount,
            proposalCount: result.data.proposalCount,
            totalJobsCount: result.data.totalJobsCount,
            totalLeadsCount: result.data.totalLeadsCount,
            userData : result.data.userData,
            showTost : !prevState.showTost,
            message : 'User Details.'
        }),()=>{
            this.getJobDetails();
        }); 
    } catch (error) {
        this.setState((prevState)=>({
            showTost : !prevState.showTost,
            message : 'Something went wrong!.' 
        }))
    }
  }
  getJobDetails = async() => {
    try {
        const params = this.props.params.queryParams;
        this.state.toggleLoader(); //show loader
        let url = `${constants.BASE_API_URL}/jobs/job_statics?page=${this.state.page}&limit=${this.state.limit}&id=${params.id}&usertype=${params.type}&type=${this.state.job_type}`;
        let response = await axios.get(url,{
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        this.state.toggleLoader(); //hide loader
        let result = response.data;
        this.setState((prevState)=>({
            user_jobs_details : result.data.leads,
            page : result.data.page,
            limit : result.data.limit,
            totalPages : result.data.totalPages,
            totalData : result.data.totalLeads,
        }))
    } catch (error) {
        this.setState((prevState)=>({
            showTost : !prevState.showTost,
            message : 'Something went wrong!.' 
        }))
    }
  }
  getProposalDetails = async() =>{
    try {
        const params = this.props.params.queryParams;
        this.state.toggleLoader(); //show loader
        let url = `${constants.BASE_API_URL}/jobs/proposal_statics?page=${this.state.page}&limit=${this.state.limit}&id=${params.id}&usertype=${params.type}&type=proposal`;
        let response = await axios.get(url,{
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        this.state.toggleLoader(); //hide loader
        let result = response.data;
        this.setState((prevState)=>({
            user_jobs_details : result.data.proposalData,
            page : result.data.page,
            limit : result.data.limit,
            totalPages : result.data.totalPages,
            totalData : result.data.proposalCount,
        }))
    } catch (error) {
      this.setState((prevState)=>({
        showTost : !prevState.showTost,
        message : 'Something went wrong!.' 
      }))
    }
  }  
  paginationHandlig = (data,type) => {
    this.setState({
        page: parseInt(data.selected) + 1,
    },() => {
      if(type === 'porposal'){
        this.getProposalDetails();
      }else{
        this.getJobDetails();
      }
    });
  };
  getJobDataAccType = (type) =>{
     this.setState((prevState)=>({
       job_type : type,
       page : 1,
       totalData : 0,
       totalPages : 1
     }),()=>{
       if(type === 'porposal'){
         this.getProposalDetails();
       }else{
         this.getJobDetails();
       }
     })
  }
  selectChange = (event,type) => {
    this.setState((prevState, prevProps) => ({
        limit: event.target.value,
        page : 1,
        totalPages : 1
    }),() => {
        if(type === 'porposal'){
          this.getProposalDetails();
        }else{
          this.getJobDetails();
        }
    });
  };
  setPdfUrl=(url)=>{
    this.setState((prevState)=>({
        pdf_url : url
    }),()=>{
        //pdf takes time to load that's why I used loader
        this.state.toggleLoader();
        setTimeout(()=>{
            this.state.toggleLoader();
            this.state.togglePdfModel();
        },10000)
    })
}

  render() {
      return (
        <Row>
          <Col md={12}>
          { this.state.showLoader && <Loader /> }
          <ToastContainer className="p-3" position="top-end">
                <Toast
                    show={this.state.showTost}
                    onClose={()=> { this.state.toggleToast()}}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">Tradesk</strong>
                        <small>just now</small>
                    </Toast.Header>
                    <Toast.Body>{this.state.message}</Toast.Body>
                </Toast>
            </ToastContainer>
            <div className="pageTitle__outer">
              <h2 className="pageTitle">User Analytic</h2>
              <div className="filterBtn">
                <Form.Group>
                  <Form.Select>
                    <option>Monthly</option>
                    <option>Yearly</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
          </Col>

          <Col md="12" className="mb-4">
            <div className="whiteBox">
              <Row>
                <Col md="3">
                  <div className="userDetail__block">
                    <h4 className="sectionTitle">User Details</h4>
                    <div className="userPersonal__detail text-center mt-4">
                      <img src={(this.state.client_details.image) ? this.state.client_details.image : "/images/img01.svg"} alt="user-img" />
                      <h6 className="mt-3 mb-1">{this.state.client_details.name}</h6>
                      <p>
                        <small>{this.state.client_details.email}</small>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md="5">
                  <div className="userDetail__block">
                    <h4 className="sectionTitle">Revenue Statistic</h4>
                    <div className="jobStatistic__detail mt-3">
                        <ul>
                            <li>
                                <small>Total Revenue</small> <br />
                                <b>${this.state.userData.length > 0 ?  this.state.userData[0].totalRevenue.toLocaleString('en-US') : 0}</b>
                            </li>
                            <li>
                                <small>Completed Revenue Amount</small> <br />
                                <b>${this.state.userData.length > 0 ?  this.state.userData[0].completedRevenue.toLocaleString('en-US') : 0}</b>
                            </li>
                            <li>
                                <small>Total Proposal Amount</small> <br />
                                <b>${this.state.userData.length > 0 ? this.state.userData[0].totalProposalAmount.toLocaleString('en-US') : 0}</b>
                            </li>
                            <li>
                                <small>Inprocess Revenue Amount</small> <br />
                                <b>${this.state.userData.length > 0 ? this.state.userData[0].InprocessRevenue.toLocaleString('en-US') : 0 }</b>
                            </li>
                        </ul>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="userDetail__block border-0">
                    <h4 className="sectionTitle">Job Statistic</h4>
                    <div className="jobStatistic__detail mt-3">
                      <ul>
                        <li>
                          <small>Total Leads</small> <br />
                          <b>{this.state.totalLeadsCount.toLocaleString('en-US')}</b>
                        </li>
                        <li>
                          <small>Completed Jobs</small> <br />
                          <b>{this.state.totalJobsCount.toLocaleString('en-US')}</b>
                        </li>
                        <li>
                          <small>Open Jobs</small> <br />
                          <b>{this.state.openJobsCount.toLocaleString('en-US')}</b>
                        </li>
                        <li>
                          <small>Proposal Sent</small> <br />
                          <b>{this.state.proposalCount.toLocaleString('en-US')}</b>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

          <Col md={12}>
            <div className="tableStyle">
              <Tab.Container defaultActiveKey="jobs">
                <Nav variant="pills" className="customTabs">
                  <Nav.Item>
                    <Nav.Link eventKey="jobs" onClick={()=>{ this.getJobDataAccType('job')}}>Jobs</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="leads" onClick={()=>{ this.getJobDataAccType('lead')}}>Leads</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="porposal" onClick={()=>{ this.getJobDataAccType('porposal')}}>Proposal</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="jobs">
                    <Table>
                      <thead>
                        <tr>
                          <th>Project</th>
                          {/* <th>Description</th> */}
                          <th>Status</th>
                          <th>Issued Date</th>
                        </tr>
                      </thead>
                      <tbody>
                          {this.state.user_jobs_details.map((item,index)=>(
                            <tr key={index}>
                              <td>{item.project_name}</td>
                              {/* <td>{item.description}</td> */}
                              <td>{item.status}</td>
                              <td>{moment(item.createdAt).format('MM/DD/YYYY')}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="d-flex align-items-center showingData">
                          <Form.Label className="me-2 mb-0">
                            showing { this.state.limit } from{" "}
                            { this.state.totalData }
                          </Form.Label>
                          <Form.Select
                              defaultValue={this.state.limit}
                              onChange={(event)=>this.selectChange(event,'job')}
                          >
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={40}>40</option>
                              <option value={50}>50</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.totalPages}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={(event)=>this.paginationHandlig(event,'job')}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="leads">
                    <Tab.Pane eventKey="leads">
                      <Table>
                        <thead>
                          <tr>
                            <th>Project</th>
                            {/* <th>Description</th> */}
                            <th>Status</th>
                            <th>Issued Date</th>
                          </tr>
                        </thead>
                        <tbody>
                            {this.state.user_jobs_details.map((item,index)=>(
                              <tr key={index}>
                                <td>{item.project_name}</td>
                                {/* <td>{item.description}</td> */}
                                <td>{item.status}</td>
                                <td>{moment(item.createdAt).format('MM/DD/YYYY')}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="d-flex align-items-center showingData">
                              <Form.Label className="me-2 mb-0">
                                showing { this.state.limit } from{" "}
                                { this.state.totalData }
                              </Form.Label>
                              <Form.Select
                                  defaultValue={this.state.limit}
                                  onChange={(event)=>this.selectChange(event,'lead')}
                              >
                                  <option value={10}>10</option>
                                  <option value={20}>20</option>
                                  <option value={30}>30</option>
                                  <option value={40}>40</option>
                                  <option value={50}>50</option>
                              </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.totalPages}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={3}
                              onPageChange={(event)=>this.paginationHandlig(event,'lead')}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                          />
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Pane>

                  <Tab.Pane eventKey="porposal">
                    <Tab.Pane eventKey="proposal">
                      <Table>
                        <thead>
                          <tr>
                            <th>Project</th>
                            {/* <th>Description</th> */}
                            <th>Status</th>
                            <th>Issued Date</th>
                            <th>Proposal Amount</th>
                            <th>Proposal Estimate</th>
                            {/* <th>PDF</th> */}
                          </tr>
                        </thead>
                        <tbody>
                            {this.state.user_jobs_details.map((item,index)=>(
                              <tr key={index}>
                                <td>{item.project_name}</td>
                                {/* <td>{item.description}</td> */}
                                <td>{item.status}</td>
                                <td>{moment(item.createdAt).format('MM/DD/YYYY')}</td>
                                <td>
                                  {Array.isArray(item.proposals) && item.proposals.map((items,indexes)=>(                          
                                    <span key={indexes}>{ (indexes ? ', ' : '') + '$'+parseFloat(items.total).toLocaleString('en-US')}</span> 
                                   ))}
                                </td>
                                <td>
                                  {Array.isArray(item.proposals) && item.proposals.map((items,indexes)=>(                          
                                    <span key={indexes}>{ (indexes ? ', ' : '') +items.estimate}</span> 
                                   ))}  
                                </td>
                                {/* <td>
                                    <img onClick={()=>{this.setPdfUrl(item.invoice_url)}} className='mr-1' src={'/images/pdf_icon.png'} width={"20px"} />
                                    <img onClick={()=>{this.downloadPdf(item.invoice_url)}} src={'/images/download.png'} width={"20px"} />
                                </td> */}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="d-flex align-items-center showingData">
                              <Form.Label className="me-2 mb-0">
                                showing { this.state.limit } from{" "}
                                { this.state.totalData }
                              </Form.Label>
                              <Form.Select
                                  defaultValue={this.state.limit}
                                  onChange={(event)=>this.selectChange(event,'proposal')}
                              >
                                  <option value={10}>10</option>
                                  <option value={20}>20</option>
                                  <option value={30}>30</option>
                                  <option value={40}>40</option>
                                  <option value={50}>50</option>
                              </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.totalPages}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={3}
                              onPageChange={(event)=>this.paginationHandlig(event,'porposal')}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                          />
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
            <Modal
                show={this.state.showPdfModel}
                onHide={this.state.togglePdfModel}
                backdrop="static"
                keyboard={false}
                >
              <Modal.Header closeButton>
                <Modal.Title>View PDF</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                 <iframe title="user_proposal_pdfs" className="pdf" width="100%" height="600" frameBorder="0" src={`https://docs.google.com/gview?url=${this.state.pdf_url}&embedded=true`}></iframe>
              </Modal.Body>
            </Modal>
        </Row>
      )}
}

export default withRouter(UserDetails);
